import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';

function PortfolioOneSlide({ image, client, title, desc }) {
    return (
        <div className="single-project">
            <div className="project-contents">
                <div className="row">
                    <div className="project-details col-lg-4 offset-lg-1 pl-lg-0 order-2 order-lg-1 col-12">
                        <div className="project-meta">
                            <div className="client-info d-inline">
                                <span>
                                    <AiOutlineUser /> ลูกค้า:
                                </span>{' '}
                                <span>{client}</span>
                            </div>
                        </div>
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="project-thumbnail col-lg-5 offset-lg-1 p-lg-0 order-1 order-lg-2 col-12">
                        <img className='popup-gallery bg-cover' src={image} alt="building materials" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PortfolioOneSlide;
