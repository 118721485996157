import React from 'react';
import bannerBg from '../assets/img/page-banner.webp';
import FaqAccordion from '../components/FaqAccordion';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';

function Faq() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} heading="คำถามที่พบบ่อย" currentPage="Faq" />
            <section className="faq-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInUp">
                            <div className="faq-content">
                                <FaqAccordion
                                    question="เราทำอะไร"
                                    answer="เป็นโรงงานผลิตอิฐบล็อก ท่อปูน เสารั้ว เสาปูน และจำหน่ายวัสดุก่อสร้าง"
                                />
                                <FaqAccordion
                                    question="ร้านอยู่ไหน"
                                    answer="130 หมู่บ้านดอนมะยาง ถนนอรุณประเสริฐ ตำบลตาดทอง อำเภอเมือง จังหวัดยโสธร 35000"
                                />
                                <FaqAccordion
                                    question="ขายอะไรบ้าง มีสินค้ามีอะไรบ้าง"
                                    answer="ขายวัสดุก่อสร้าง โต๊ะม้าหินอ่อน ศาลพระภูมิ ศาลพระพรหม ศาลเจ้าที่ เครื่องตั้งศาล ธาตุ เจดีย์ เครื่องสังฆภัณฑ์ "
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp">
                            <div className="faq-content">
                                <FaqAccordion
                                    question="มีบริการส่งไหม"
                                    answer="ร้านสระบุรีอิฐบล๊อคมีบริการส่งค่ะ"
                                />
                                <FaqAccordion
                                    question="อิฐมอญ / อิฐแดง ดียังไง"
                                    answer="สามารถหาซื้อได้ง่ายราคาถูก และหาช่างก่อได้ง่าย ทนทานต่อสภาพความร้อน รับน้ำหนักในการเจาะแขวนได้ดีกว่าอิฐประเภทอื่น มีขนาดเล็กจึงสามารถใช้ต่อเติมในพื้นที่เล็กได้ดี
                                    "
                                />
                                <FaqAccordion
                                    question="โต๊ะม้าหินอ่อน ดียังไง"
                                    answer="ผลิตด้วยวัสดุชั้นดี คุณภาพสูง ทนแดด ทนฝน ไม่ชำรุดง่าย"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer1 />
        </>
    );
}

export default Faq;
