import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/img/portfolio/cases1.webp';
import img2 from '../../assets/img/portfolio/cases2.webp';
import img3 from '../../assets/img/portfolio/cases3.webp';
import img4 from '../../assets/img/portfolio/cases4.webp';
import img5 from '../../assets/img/portfolio/cases5.webp';
import img6 from '../../assets/img/portfolio/cases6.webp';
import img7 from '../../assets/img/portfolio/cases7.webp';
import img8 from '../../assets/img/portfolio/cases8.webp';
import img9 from '../../assets/img/portfolio/cases9.webp';

const portfolioTwoData = [
    {
        id: uuidv4(),
        image: img1,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img2,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img4,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img5,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img6,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img7,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img8,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: img9,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },
];

export default portfolioTwoData;
