import { FaLandmark, FaTorah, FaGifts, FaRegBuilding, FaFan } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/home1/bg1.webp';
import thumb2 from '../../assets/img/home1/bg2.webp';
import thumb3 from '../../assets/img/home1/bg3.webp';
import thumb4 from '../../assets/img/home1/bg4.webp';
import thumb5 from '../../assets/img/home1/bg5.webp';
import thumb6 from '../../assets/img/home1/bg6.webp';

const servicesTwoData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        icon: <FaRegBuilding />,
        heading: 'จำหน่าย วัสดุก่อสร้าง',
        text: 'วัสดุก่อสร้าง คือ วัสดุที่ใช้ในจุดประสงค์สำหรับการก่อสร้างที่เกี่ยวข้องกับที่อยู่อาศัยของมนุษย์และสิ่งมีชีวิตที่มนุษย์สร้างขึ้นรวมไปถึงสถาปัตยกรรมที่ถูกสร้างขึ้นวัสดุก่อสร้างมีการหลากหลายในทางวัสดุตั้งแต่ ดินจนถึงโลหะพลาสติกหรือแก้ววัสดุแบ่งแยกในหลายด้านไม่ว่าโครงสร้างทางวัตถุ',
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        icon: <FaLandmark />,
        heading: 'จำหน่าย ศาลพระภูมิ',
        text: 'ศาลพระภูมิ หมายถึง ศาลที่สร้างขึ้นเพื่อให้เป็นที่สถิตของเทพารักษ์ พบได้ทั่วไปในประเทศไทยและยังพบว่ามีปรากฏในประเทศลาวและประเทศกัมพูชาอีกด้วยมีลักษณะเป็นบ้านหรือวิหารหลังเล็กตั้งอยู่บนเสาเดี่ยวหรือปะรำทำจากปูนหรือไม้เป็นต้นตั้งไว้ในจุดที่เชื่อว่าเป็นมงคล',
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        icon: <FaLandmark />,
        heading: 'จำหน่าย ธาตุ เจดีย์',
        text: 'เจดีย์ หมายถึงสิ่งก่อสร้างหรือสิ่งของที่สร้างขึ้นเพื่อเป็นที่ระลึกถึงและเคารพบูชา สถูปหมายถึงสิ่งก่อสร้างเหนือหลุมฝังศพหรือสร้างขึ้นเพื่อบรรจุอัฐิธาตุของผู้ที่ล่วงลับไปแล้วเพื่อให้ลูกหลานและผู้เคารพนับถือได้สักการบูชา',
    },

    {
        id: uuidv4(),
        thumbnail: thumb4,
        icon: <FaTorah />,
        heading: 'รับแกะสลักป้าย หินอ่อน',
        text: 'ยินดีให้คำปรึกษา และคำแนะนำในการสั่งทำป้ายอัฐิ ที่ถูกต้องเหมาะสมกับแต่ละงาน ด้วยวัสดุเกรดทำป้าย ไม่ใช่เกรดปูพื้น และรูปหินที่คงทนถาวรจริง สีไม่จืดจาง แม้ต้องเจอแดดฝน',
    },

    {
        id: uuidv4(),
        thumbnail: thumb5,
        icon: <FaGifts />,
        heading: 'เครื่องสังฆภัณฑ์',
        text: 'งานบุญ งานบวช งานศพ สังฆทานเครื่องกันหนาว สังฆทานบุญ ผ้าไตรจีวร 7 ชิ้น ชุดธูปเทียน LED ด้ายมงคล 9 เส้น สายสิญจน์ ด้ายดิบ ใช้ในงานพิธีสำคัญ มัดข้อไม้ ข้อมือ โยงทำพิธี หรือทำพิธีทางศาสนาต่าง ๆ และหมอนขิด',
    },

    {
        id: uuidv4(),
        thumbnail: thumb6,
        icon: <FaFan />,
        heading: 'บริการพวงหรีด',
        text: 'พวงหรีด พัดลมพวงหรีด บริการส่งฟรี เขตเทศบาลเมืองยโสธร',
    },
];

export default servicesTwoData;
