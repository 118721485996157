import { AiOutlineFieldTime, AiOutlinePhone } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';

const WidgetGetinTouchData = [
    {
        id: uuidv4(),
        icon: <AiOutlineFieldTime />,
        heading: 'วันเวลาทำการ',
        text: 'เปิดทุกวัน จันทร์-เสาร์',
        text2: 'เวลา 8.00 น. - 17.00 น.',
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'เบอร์โทร',
        text: '095-8370587',
        text2: '045-712076',
        text3: '045-714420',
    },
];

export default WidgetGetinTouchData;
