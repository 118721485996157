import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import portfolioTwoData from './porfolioTwoData';
import portfolioTwoDataTwo from './porfolioTwoDataTwo';
import PortfolioTwoItem from './PortfolioTwoItem';

function Portfolio2() {
    return (
        <section className="portfolio-wrapper cases-carousel-wrapper section-padding mbm-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12 text-center mb-30">
                        <div className="section-title-3">
                            <h1>ภาพผลงาน</h1>
                        </div>
                    </div>
                </div>
            </div>

            <Swiper
                spaceBetween={30}
                autoplay
                className="portfolio-carousel-wrapper owl-carousel"
                breakpoints={{
                    0: {
                        slidesPerView: 2,
                    },

                    767: {
                        slidesPerView: 3,
                    },

                    992: {
                        slidesPerView: 4,
                    },
                }}
            >
                {portfolioTwoData.map((data) => (
                    <SwiperSlide>
                        <PortfolioTwoItem
                            key={data.id}
                            img={data.image}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Swiper
                spaceBetween={30}
                autoplay
                className="portfolio-carousel-wrapper owl-carousel"
                breakpoints={{
                    0: {
                        slidesPerView: 2,
                    },

                    767: {
                        slidesPerView: 3,
                    },

                    992: {
                        slidesPerView: 4,
                    },
                }}
            >
                {portfolioTwoDataTwo.map((data) => (
                    <SwiperSlide>
                        <PortfolioTwoItem
                            key={data.id}
                            img={data.image}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

export default Portfolio2;
