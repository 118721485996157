import { AiOutlineFieldTime, AiOutlinePhone } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const contactData = [
    {
        id: uuidv4(),
        icon: <AiOutlineFieldTime />,
        heading: 'วันเวลาทำการ',
        text: 'Time',
        item1: 'เปิดทุกวัน จันทร์-เสาร์ ',
        item2: 'เวลา 8.00 น. - 17.00 น.',
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'เบอร์โทร',
        text: 'Tel',
        item1: 'มือถือ : 095-8370587 ',
        item2: 'โทรศัพท์ : 045-712076, 045-714420',
    },

    {
        id: uuidv4(),
        icon: <FaMapMarkerAlt />,
        heading: 'ที่อยู่',
        text: 'Location',
        item1: '130 หมู่บ้านดอนมะยาง ถนนอรุณประเสริฐ ',
        item2: 'ตำบลตาดทอง อำเภอเมือง จังหวัดยโสธร 35000',
    },
];

export default contactData;
