import { v4 as uuidv4 } from 'uuid';
import img1 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other1.webp";
import img2 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other2.webp";
import img3 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other3.webp";
import img4 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other4.webp";
import img5 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other5.webp";
import img6 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other6.webp";
import img7 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other7.webp";
import img8 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other8.webp";
import img9 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other9.webp";
import img10 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other10.webp";
import img11 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other11.webp";
import img12 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other12.webp";
import img13 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other13.webp";
import img14 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other14.webp";
import img15 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other15.webp";
import img16 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img_other16.webp";

const MarbleHorseTableSlideData = [
    {
        id: uuidv4(),
        image: img1,
        name: 'Rosalina D.',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img2,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img3,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img4,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img5,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img6,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img7,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img8,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img9,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img10,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img11,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img12,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img13,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img14,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img15,
        name: '',
        heading: '',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img16,
        name: '',
        heading: '',
        cost: '',
    },
];

export default MarbleHorseTableSlideData;
