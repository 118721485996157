import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.webp";
import MobileMenu from "../MobileMenu";
import OffsetMenu from "../OffsetMenu";
import MainMenu from "./MainMenu";
import Topbar from "./Topbar";
import {
  FaLine,
  FaFacebook,
  FaTiktok,
  FaMapMarked,
  FaPhoneAlt,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { SiLine } from "react-icons/si";

function Header1() {
  const [offset, setOffset] = useState(false);
  const handleOffset = () => {
    setOffset(!offset);
  };
  return (
    <>
      <OffsetMenu
        handleOffset={handleOffset}
        className={offset ? "offset-menu show" : "offset-menu"}
      />
      <header className="header-wrap header-1">
        <Topbar />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-8 col-lg-3">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="Saraburiitblock" width="450" height="100%" />
                </Link>
              </div>
            </div>
            <div className="col-4 d-block d-lg-none">
              <div className="mobile-nav-wrap">
                <MobileMenu />
              </div>
            </div>
            <div className="col-12 d-none d-md-block col-lg-9">
              <div className="header-contact-info text-lg-right">
                <div className="single-element">
                  <p className="bottom-item">
                    <FaMapMarked className="icon" />
                    130 หมู่บ้านดอนมะยาง ถนนอรุณประเสริฐ <br></br>ตำบลตาดทอง
                    อำเภอเมือง จังหวัดยโสธร 35000
                  </p>
                </div>
                <div className="single-element">
                  <p className="bottom-item">
                    <a className="phonelink" href="tel:095-8370587">
                      <FaPhoneAlt className="icon" />
                      095-8370587
                    </a>
                  </p>
                  <p className="bottom-item">
                    <a className="phonelink" href="https://lin.ee/xhtCRxO">
                      <FaLine className="icon" />
                      @saraburi_it_block
                    </a>
                  </p>
                </div>
                <div className="social-media">
                  <a
                    href="https://www.facebook.com/Sraburiitblock"
                    className="fa fa-facebook"
                  >
                    <FaFacebook className="icon" />
                  </a>
                  <a href="https://lin.ee/xhtCRxO" className="fa fa-line">
                    <SiLine className="icon" />
                  </a>
                  {/* <a href="#" className="fa fa-instagram"><FaInstagram className="icon"/></a>
                  <a href="#" className="fa fa-youtube"><FaYoutube className="icon"/></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="menu-wrapper d-none d-lg-block">
          <div className="container">
            <MainMenu hanldeOffset={handleOffset} />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header1;
