import React, { useState } from "react";
import imgBg from "../../assets/img/product/buildingmaterials/imgBg.webp";
import img2 from "../../assets/img/product/buildingmaterials/img2.webp";

import monbrickimg from "../../assets/img/product/buildingmaterials/monbrick/mon-brick-img.webp";
import monbrick2 from "../../assets/img/product/buildingmaterials/monbrick/2.webp";
import monbrick4 from "../../assets/img/product/buildingmaterials/monbrick/4.webp";
import lightweightbrick from "../../assets/img/product/buildingmaterials/lightweightbrick/lightweight-brick.webp";

import brickShowPattern_img1 from "../../assets/img/product/buildingmaterials/brickShowPattern/img1.webp";
import brickShowPattern_img2 from "../../assets/img/product/buildingmaterials/brickShowPattern/img2.webp";
import brickShowPattern_img3 from "../../assets/img/product/buildingmaterials/brickShowPattern/img3.webp";
import brickShowPattern_img4 from "../../assets/img/product/buildingmaterials/brickShowPattern/img4.webp";
import brickShowPattern_img5 from "../../assets/img/product/buildingmaterials/brickShowPattern/img5.webp";
import brickShowPattern_img6 from "../../assets/img/product/buildingmaterials/brickShowPattern/img6.webp";

function BrickBlock() {
  return (
    <>
      <section className="BrickBlock-section ">
        <div className="container">
          <div className="section-padding">
            <div className="content_one">
              <div className="row">
                <div className="col-xl-6 col-md-6 col-12">
                  <div className="section-title">
                    <h2>อิฐบล็อกทึบ</h2>
                    <p>
                      อิฐบล็อก หรืออิฐคอนกรีต
                      เป็นอิฐที่มีสีเทาและมีรูอยู่ตรงกลาง
                      มีขนาดใหญ่เป็นมาตรฐานและมีราคาถูก
                      เมื่อเทียบราคาในการก่อผนังบนพื้นที่เท่าๆกันกับอิฐมอญและอิฐมวลเบา{" "}
                    </p>
                  </div>
                  <div className="content">
                    <h3>ข้อดีของอิฐบล็อก</h3>
                    <p>
                      - มีราคาถูกกว่าอิฐประเภทอื่นๆ
                      <br />
                      - ประหยัดเวลาในการก่อเพราะมีขนาดใหญ่จึงก่อได้รวดเร็ว
                      <br />
                      - ขนาดก้อนมีมาตรฐานมากกว่าอิฐมอญ
                      <br />
                      - ระบายความร้อนได้ดีเนื่องจากมีรูอยู่ตรงกลาง
                      <br />
                    </p>
                    <br />
                    <h3>ข้อเสียของอิฐบล็อก</h3>
                    <p>
                      - มีโอกาสรั่วซึมได้มากกว่าอิฐประเภทอื่น
                      <br />
                      - มีความทนทานน้อยกว่าการก่อด้วยอิฐมอญ
                      รับน้ำหนักในการเจาะแขวนได้น้อย
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 mt-xl-0 col-12">
                  <img className="content_img" src={imgBg} alt="brick" />
                </div>
              </div>
            </div>

            <div className="brickShowPattern">
              <div className="col-xl-12 col-md-12 mt-5 mt-xl-5 col-12">
                <div className="section-title">
                  <h2>อิฐโชว์ลาย (ช่องลม)</h2>
                  <p>
                    คุณสมบัติของบล็อกช่องลม คือการระบายความร้อน
                    ถ่ายเทให้อากาศหมุนเวียน และเป็นช่องแสงให้แสงลอดผ่าน
                    แต่ลวดลายดีไซน์ก็เหมาะที่จะใช้ตกแต่งพื้นที่ทั้งภายนอกและภายในบ้านด้วยเช่นกัน
                    สามารถปรับตกแต่งได้หลากหลายแบบ{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-12 mt-0 mt-xl-0 col-12">
                <div className="row">
                  <div className="col-xl-4 col-md-6 mt-0 mt-xl-3 col-12">
                    <img
                      className="img"
                      src={brickShowPattern_img1}
                      alt="brick"
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 mt-3 mt-xl-3 col-12">
                    <img
                      className="img"
                      src={brickShowPattern_img2}
                      alt="brick"
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 mt-3 mt-xl-3 col-12">
                    <img
                      className="img"
                      src={brickShowPattern_img3}
                      alt="brick"
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 mt-3 mt-xl-3 col-12">
                    <img
                      className="img"
                      src={brickShowPattern_img4}
                      alt="brick"
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 mt-3 mt-xl-3 col-12">
                    <img
                      className="img"
                      src={brickShowPattern_img5}
                      alt="brick"
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 mt-3 mt-xl-3 col-12">
                    <img
                      className="img"
                      src={brickShowPattern_img6}
                      alt="brick"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mon-brick section-padding">
            <div className="row">
              <div className="col-xl-6 col-12">
                <div className="section-title">
                  <h2>อิฐมอญ / อิฐแดง</h2>
                  <p>
                    สำหรับอิฐมอญในร้านสระบุรีอิฐบล๊อคยโสธร หรืออิฐแดง
                    ที่ผลิตจากดินเหนียวมาปั้นเป็นรูปทรงแล้วนำไปเผา มีขนาดเล็ก
                    อิฐมอญสามารถทนต่อสภาพแวดล้อมทั้งความร้อนและแสงแดดได้ดี
                    จึงเหมาะกับทำผนังภายนอกโดยผสมปูนก่อทั่วไปแล้วทำการก่อผนังได้เลย
                    ในด้านช่างก่อสร้างจะสามารถก่ออิฐมอญได้เป็นพื้นฐานจึงไม่จำเป็นต้องใช้ช่างเฉพาะทาง
                  </p>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-6 col-xl-6 col-12 text-align-center">
                      <img src={monbrick2} alt="brick" />
                    </div>
                    <div className="col-md-6 col-xl-6 col-12 text-align-center">
                      <img src={monbrick4} alt="brick" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 mt-1 mt-xl-0 col-12">
                <img className="content_img" src={monbrickimg} alt="brick" />
              </div>
            </div>

            <div className="mon-brick-content">
              <div className="row">
                <div className="col-xl-6 mt-3 mt-xl-4 col-12">
                  <div className="mon-brick-content-block">
                    <h3>ข้อดีของอิฐมอญ</h3>
                    <p>
                      - สามารถหาซื้อได้ง่ายราคาถูก และหาช่างก่อได้ง่าย
                      <br />
                      - ทนทานต่อสภาพความร้อน
                      <br />
                      - รับน้ำหนักในการเจาะแขวนได้ดีกว่าอิฐประเภทอื่น
                      <br />
                      - มีขนาดเล็กจึงสามารถใช้ต่อเติมในพื้นที่เล็กได้ดี
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 mt-3 mt-xl-4 col-12">
                  <div className="mon-brick-content-block">
                    <h3>ข้อเสียของอิฐมอญ</h3>
                    <p>
                      - สะสมความร้อนได้ดีจึงทำให้บ้านร้อนกว่าอิฐประเภทอื่น
                      (แต่สามารถแก้ไขโดยการก่ออิฐ 2 ชั้น)
                      <br />
                      - มีขนาดเล็กใช้เวลาในการก่อนานกว่าประเภทอื่น
                      <br />
                      - ขนาดไม่มาตรฐานต้องฉาบปูนหนาเพื่อให้ได้ระดับที่เสมอกัน
                      <br />
                      - ดูดซึมน้ำสูงจึงต้องแช่อิฐในน้ำก่อนการก่อผนัง
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lightweight-brick section-padding section-bg">
          <div className="container">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-12">
                  <div className="section-title text-align-center">
                    <h2>อิฐมวลเบา</h2>
                    <p>
                      อิฐมวลเบา เป็นผลิตภัณฑ์คอนกรีตชนิดใหม่
                      ผลิตจากวัตถุดิบธรรมชาติ ได้แก่ ปูนซีเมนต์ปอร์ตแลนด์ ทราย
                      ปูนขาว ยิบซั่ม น้ำ และสารกระจายฟองอากาศ
                      ส่วนผสมพิเศษในอัตราส่วนที่เป็นสูตรเฉพาะตัว
                      ผลิตภัณฑ์คอนกรีตมวลเบาเป็นวัสดุก่อสร้างยุคใหม่ที่มุ่งเน้นให้เกิดประโยชน์สูงสุดจากการนำไปใช้งานทุกด้าน{" "}
                    </p>
                  </div>
                  <div className="content">
                    <div className="row">
                      <div className="col-md-12 col-xl-12 col-12 text-align-center">
                        <img src={lightweightbrick} alt="brick" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mon-brick-content">
                <div className="row">
                  <div className="col-xl-6 mt-3 mt-xl-3 col-12">
                    <div className="content-block">
                      <h3>ข้อดีของอิฐมวลเบา</h3>
                      <p>
                        - มีน้ำหนักเบา ขนย้ายสะดวก
                        <br />
                        - ติดตั้งได้รวดเร็วเพราะมีขนาดใหญ่
                        <br />
                        - ไม่สิ้นเปลืองปูนในการก่อเพราะอิฐมีขนาดที่ได้มาตรฐาน
                        <br />
                        - มีรูพรุนทั่วทั่งก้อนจึงเป็นฉนวนกันความร้อนได้ดี
                        <br />
                        - ตัวอิฐสามารถดูดซับเสียงได้จึงกักเก็บเสียงได้ดี
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-3 mt-xl-3 col-12">
                    <div className="content-block">
                      <h3>ข้อเสียของอิฐมวลเบา</h3>
                      <p>
                        - มีราคาสูงกว่าอิฐประเภทอื่น
                        <br />
                        -
                        ต้องใช้ช่างที่มีความชำนาญในการก่อเพื่อให้ผนังได้มาตรฐาน
                        <br />
                        -
                        ต้องมีอุปกรณ์เฉพาะอิฐมวลในการก่อไม่สามารถใช้ปูนร่วมกับอิฐประเภทอื่นได้
                        <br />
                        -
                        ในการเจาะผนังอิฐมวลเบาต้องใช้พุกเฉพาะอิฐมวลเบาเพื่อยึดเกาะ
                        <br />
                        -
                        อิฐมีรูพรุนดูดซับความชื้นจึงไม่เหมาะกับการก่อสร้างห้องน้ำ
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BrickBlock;
