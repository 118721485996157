import React from 'react';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

const menuItems = [
    {
        title: 'หน้าแรก',
        path: '/',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        
    },

    {
        title: 'เกี่ยวกับเรา',
        path: '/about',
    },

    {
        title: 'สินค้าของเรา',
        path: '#',

        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'วัสดุก่อสร้าง',
                path: '/building-materials',
                subNav: [
                    {
                        title: 'A',
                        path: '/shrine',
                    },
                    {
                        title: 'B',
                        path: '/pagoda',
                    },
                ],
            },
            {
                title: 'ศาลพระภูมิ / ศาลเจ้าที่',
                path: '/shrine',
            },
            {
                title: 'ธาตุ เจดีย์',
                path: '/pagoda',
            },
            {
                title: 'แกะสลักป้าย หินอ่อน',
                path: '/engraved-sign',
            },
            {
                title: 'เครื่องสังฆภัณฑ์',
                path: '/offering',
            },
        ],
    },

    {
        title: 'บริการของเรา',
        path: '/services',
    },

    {
        title: 'คำถามที่พบบ่อย',
        path: '/faq',
    },

    {
        title: 'ติดต่อเรา',
        path: '/contact',
    },
];

export default menuItems;
