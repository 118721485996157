import { v4 as uuidv4 } from 'uuid';
import img1 from "../../assets/img/product/Pagoda/slide/img1.webp";
import img2 from "../../assets/img/product/Pagoda/slide/img2.webp";
import img3 from "../../assets/img/product/Pagoda/slide/img3.webp";
import img4 from "../../assets/img/product/Pagoda/slide/img4.webp";
import img5 from "../../assets/img/product/Pagoda/slide/img5.webp";
import img6 from "../../assets/img/product/Pagoda/slide/img6.webp";
import img7 from "../../assets/img/product/Pagoda/slide/img7.webp";

const PagodaSlideData = [
    {
        id: uuidv4(),
        image: img1,
        name: 'Pagoda',
        heading: 'a building or an item created to commemorate and worship the stupa',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img2,
        name: 'Pagoda',
        heading: 'a building or an item created to commemorate and worship the stupa',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'Pagoda',
        heading: 'a building or an item created to commemorate and worship the stupa',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img4,
        name: 'Pagoda',
        heading: 'a building or an item created to commemorate and worship the stupa',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img5,
        name: 'Pagoda',
        heading: 'a building or an item created to commemorate and worship the stupa',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img6,
        name: 'Pagoda',
        heading: 'a building or an item created to commemorate and worship the stupa',
        cost: '',
    },

    {
        id: uuidv4(),
        image: img7,
        name: 'Pagoda',
        heading: 'a building or an item created to commemorate and worship the stupa',
        cost: '',
    },
];

export default PagodaSlideData;
