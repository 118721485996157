import React from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { SiLine } from "react-icons/si";
import {
  FaLine,
  FaFacebook,
} from "react-icons/fa";

const MenuContent = () => (
  <div className="mobile-menu-bottom">
    <ul>
      <li className="bottom-item">
        <FaLine className="icon" />
        @saraburi_it_block
      </li>
      <li className="bottom-item">
        <AiOutlinePhone className="icon" />
        
        <a href="tel:0958370587">095-8370587</a>
      </li>
      <li className="bottom-item">
        <AiOutlinePhone className="icon" />
        
        <a href="tel:045712076">045-712076</a>
      </li>
      <li className="bottom-item">
        <AiOutlinePhone className="icon" />
        <a href="tel:045714420">045-714420</a>
        
      </li>
    </ul>
      <div className="social-media">
        <a
          href="https://www.facebook.com/Sraburiitblock"
          className="fa fa-facebook"
        >
          <FaFacebook className="icon" />
        </a>
        <a href="https://lin.ee/xhtCRxO" className="fa fa-line">
                    <SiLine className="icon" />
                  </a>
      </div>
  </div>
);

export default MenuContent;
