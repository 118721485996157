import { v4 as uuidv4 } from 'uuid';

import imgTwo1 from '../../assets/img/portfolio/casesTwo1.webp';
import imgTwo2 from '../../assets/img/portfolio/casesTwo2.webp';
import imgTwo3 from '../../assets/img/portfolio/casesTwo3.webp';
import imgTwo4 from '../../assets/img/portfolio/casesTwo4.webp';
import imgTwo5 from '../../assets/img/portfolio/casesTwo5.webp';
import imgTwo6 from '../../assets/img/portfolio/casesTwo6.webp';
import imgTwo7 from '../../assets/img/portfolio/casesTwo7.webp';
import imgTwo8 from '../../assets/img/portfolio/casesTwo8.webp';
import imgTwo9 from '../../assets/img/portfolio/casesTwo9.webp';

const porfolioTwoDataTwo = [
    {
        id: uuidv4(),
        image: imgTwo1,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo2,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo3,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo4,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo5,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo6,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo7,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo8,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

    {
        id: uuidv4(),
        image: imgTwo9,
        name: 'building materials',
        heading: 'building materials',
        cost: '$',
    },

];

export default porfolioTwoDataTwo;
