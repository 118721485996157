import React, { useState } from "react";
import img1 from "../../assets/img/product/buildingmaterials/rockandsand/img1.webp";
import img2 from "../../assets/img/product/buildingmaterials/rockandsand/img2.webp";
import img3 from "../../assets/img/product/buildingmaterials/rockandsand/img3.webp";
import img4 from "../../assets/img/product/buildingmaterials/rockandsand/img4.webp";
import img5 from "../../assets/img/product/buildingmaterials/rockandsand/img5.webp";
import img6 from "../../assets/img/product/buildingmaterials/rockandsand/img6.webp";

function RockAndSandAndMortar() {
  return (
    <>
      <section className="RockAndSandAndMortar-section">
        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>หิน และ ทราย</h2>
            </div>
            <div className="content">
              <div className="row">
                <div className="col-xl-6 mt-5 col-md-6 mt-xl-3 col-12">
                  <div
                    className="content_img"
                    style={{ backgroundImage: `url(${img1})` }}
                  ></div>
                </div>
                <div className="col-xl-6 mt-5 col-md-6 mt-xl-3 col-12">
                  <div
                    className="content_img"
                    style={{ backgroundImage: `url(${img2})` }}
                  ></div>
                </div>
                <div className="col-xl-12 col-md-12 mt-5 mt-xl-5 col-12 text-align-center">
                  <div className="section-title">
                    <h3>
                      หิน 3/4 ผสมคอนกรีต (ปูน ) งานหล่อคอนกรีต เทพื้นคอนกรีต
                      งานผิวทางลาดยาง งานตกแต่งสวน และงานก่อสร้างทั่วไป{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-bg ">
          <div className="container">
            <div className="section-padding">
              <div className="text_header">
                <h2>ปูนเสือ</h2>
              </div>
              <div className="content">
                <div className="col-xl-12 mt-5 mt-xl-0 col-12">
                  <div className="row">
                    <div className="col-xl-6 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img3} alt="mortar" />
                    </div>
                    <div className="col-xl-6 mt-5 mt-xl-3 col-12">
                      <h4>ปูนเสือ-S : ปูนเสือซุปเปอร์</h4>
                      <p>
                        มีสารที่ถูกพัฒนาขึ้นมาเฉพาะ
                        ที่ทำให้เนื้อปูนมีความเข้มข้นสูง
                        มีการใช้งานที่เหมาะสมสำหรับงานก่ออิฐ ฉาบผนัง
                        และเทพื้นในงานโครงสร้างขนาดเล็ก มีเนื้อปูนที่เหนียว
                        ก่อง่าย ฉาบได้ลื่นดี
                        และงานที่ได้มีความแข็งแรงกว่าปูนซีเมนต์แบบผสมทั่วไป
                        ผนังที่ได้จะมีความแข็งแรงไม่มีแตกร้าว
                        เนื่องจากเนื้อปูนมีความยืดหดตัวน้อย
                        สามารถที่จะอุ้มน้ำและยึดเกาะได้เป็นอย่างดี
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 mt-5 mt-xl-5 col-12">
                  <div className="row">
                    <div className="col-xl-6 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img4} alt="mortar" />
                    </div>
                    <div className="col-xl-6 mt-5 mt-xl-3 col-12">
                      <h4>ปูนเสือ-พลัส</h4>
                      <p>
                        ปูนเสือ พลัส สูตรพิเศษ
                        เป็นปูนซีเมนต์ที่เหมาะกับงานฉาบโดยเฉพาะ
                        ใช้ฉาบในอิฐมอญและอิฐบล็อก
                        เนื้อปูนมีความละเอียดเป็นพิเศษที่มากกว่าปูนทั่วไป
                        ทำให้ผนังมีความเนียนเรียบสวยอย่างรวดเร็ว ไม่มีการแตกร้าว
                        เพราะมีสารช่วยฉาบพิเศษ
                        ทำให้สามารถที่จะฉาบลื่นโดยที่ไม่ต้องน้ำยาฉาบใด ๆ
                        ทั้งยังทำให้เหล่าช่างได้ประหยัดเวลาและแรงได้เป็นอย่างดี
                        พร้อมทั้งมีสารอุ้มน้ำ
                        ที่จะช่วยให้ผนังไม่เกิดการแตกร้าวง่าย ๆ
                        และยังมีการแห้งตัวในเวลาที่พอเหมาะอีกด้วย
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section-padding">
            <div className="text_header">
              <h2>ปูนราชสีห์</h2>
            </div>
            <div className="content">
              <div className="col-xl-12 mt-5 mt-xl-0 col-12">
                <div className="row">
                  <div className="col-xl-6 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img5} alt="mortar" />
                    </div>
                    <h4>ปูนราชสีห์ซูเปอร์</h4>
                    <p>
                      ปูนก่อ ฉาบ เท มอก.80-2550 ปูนซีเมนต์ผสมสูตรใหม่
                      40กก.ใช้ผสมเทียบเท่าปูนธรรมดา 50 กก.
                    </p>
                  </div>

                  <div className="col-xl-6 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img6} alt="mortar" />
                    </div>
                    <h4>ปูนราชสีห์-แดง</h4>
                    <p>
                      ปูนปอร์ตแลนด์กำลังอัดสูง ราชสีห์แดง มอก. 15 เล่ม
                      1-2555ประเภท 1ซึ่งสามารถเทียบเท่ากับมาตรฐาน ASTM C 150-07
                      TYPE I ของประเทศสหรัฐอเมริกาสำหรับผลิตท่อคอนกรีตอัดแรง
                      ใช้ผลิตพื้นสำเร็จรูป
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RockAndSandAndMortar;
