import React from 'react';
import { FaFacebookF, FaLine  } from 'react-icons/fa';

function WidgetAbout({ text, fbLink, lineLink }) {
    return (
        <>
            <div className="col-md-6 col-xl-3 col-12">
                <div className="single-footer-wid">
                    <div className="wid-title">
                        <h4>ที่อยู่</h4>
                    </div>
                    <p>{text}</p>
                    <div className="social_link">
                        <a href={fbLink} aria-label="facebook">
                            <FaFacebookF />
                        </a>
                        <a href={lineLink} aria-label="line">
                            <FaLine />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WidgetAbout;
