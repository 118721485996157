import React from 'react';
import bannerBg from '../assets/img/page-banner.webp';
import AboutFeatured from '../components/AboutFeatured';
import Approch from '../components/Approch';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';

function About() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} currentPage="เกี่ยวกับเรา" heading="เกี่ยวกับเรา" />
            <AboutFeatured />
            <Approch />
            <Footer1 />
        </>
    );
}

export default About;
