import React, { useState } from "react";
import Iron_img1 from "../../assets/img/product/buildingmaterials/Iron/img1.webp";
import img2 from "../../assets/img/product/buildingmaterials/img2.webp";

import monbrickimg from "../../assets/img/product/buildingmaterials/monbrick/mon-brick-img.webp";
import monbrick2 from "../../assets/img/product/buildingmaterials/monbrick/2.webp";
import monbrick4 from "../../assets/img/product/buildingmaterials/monbrick/4.webp";
import lightweightbrick from "../../assets/img/product/buildingmaterials/lightweightbrick/lightweight-brick.webp";

function BrickBlock() {
  return (
    <>
      <section className="Iron-section ">
        <div className="container">
          <div className="section-padding">
            <div className="content_one">
              <div className="row">
                <div className="col-xl-6 col-md-12 col-12">
                  <div className="section-title">
                    <h2>เหล็ก</h2>
                    <h5>
                      <b>เหล็ก </b>
                      เป็นที่นิยมและมีการนำมาใช้งานในอุตสาหกรรมหรือการผลิตเครื่องจักรกลต่างๆ
                      รวมทั้งใช้ใน <b>การสร้างบ้าน </b>ด้วย
                      เพราะเป็นโลหะที่มีความแข็งแรงและทนทานมาก
                    </h5>
                  </div>
                  <div className="content">
                    <h3>ขนาดเหล็กของร้านสระบุรีอิฐบล๊อค วัสดุก่อสร้างยโสธร</h3>
                    <h5>
                      - เหล็กเส้นกลม 6 มม. SR24
                      <br />
                      - เหล็กเส้นกลม 9 มม. SR24
                      <br />
                      - เหล็กข้ออ้อย 12 มม. SD40
                      <br />
                      - เหล็กข้ออ้อย 16 มม. SD40
                      <br />
                    </h5>
                  </div>
                </div>
                <div className="col-xl-6 col-md-12 mt-xl-0 col-12">
                  <div
                    className="content_img"
                    style={{ backgroundImage: `url(${Iron_img1})` }}
                  ></div>
                </div>
              </div>
              <div className="iron-nature">
                <div className="col-xl-12 col-md-12 mt-xl-0 col-12">
                  <h3>ลักษณะทั่วไปของเหล็กและเหล็กกล้า</h3>
                  <h4>
                    <b>เหล็ก </b> จะมีสัญลักษณ์ทางวิทยาศาสตร์ คือ Fe
                    มักพบได้มากในธรรมชาติ ซึ่งจะมีลักษณะเป็นสีแดงอมน้ำตาล
                    เมื่อนำเข้าใกล้กับแม่เหล็ก จะดูดติดกัน
                    ส่วนพื้นที่ที่ค้นพบเหล็กได้มากที่สุด ก็คือ
                    ตามชั้นหินใต้ดินที่อยู่บริเวณที่ราบสูงและภูเขา
                    โดยจะอยู่ในรูปของสินแร่เป็นส่วนใหญ่
                    ซึ่งก็ต้องใช้วิธีถลุงออกมา
                    เพื่อให้ได้เป็นแร่เหล็กบริสุทธิ์และสามารถนำมาใช้ประโยชน์ได้
                    <br />
                    <br />
                    <b>เหล็กกล้า </b>เป็นโลหะผสม ที่มีการผสมระหว่าง เหล็ก
                    ซิลิคอน แมงกานีส คาร์บอนและธาตุอื่นๆ อีกเล็กน้อย
                    ทำให้มีคุณสมบัติในการยืดหยุ่นสูง ทั้งมีความทนทาน แข็งแรง
                    และสามารถต้านทานต่อแรงกระแทกและภาวะทางธรรมชาติได้อย่างดีเยี่ยม
                    ที่สำคัญคือเหล็กกล้าไม่สามารถค้นพบได้ตามธรรมชาติเหมือนกับเหล็ก
                    เนื่องจากเป็นเหล็กที่สร้างขึ้นมาโดยการประยุกต์ของมนุษย์
                    แต่ในปัจจุบันก็มีการนำเหล็กกล้ามาใช้งานอย่างแพร่หลาย
                    เพราะมีต้นทุนต่ำ จึงช่วยลดต้นทุนได้เป็นอย่างมาก
                    และมีคุณสมบัติที่โดดเด่นไม่แพ้เหล็ก
                    <br />
                  </h4>
                </div>
              </div>

              <div className="iron-type">
                <div className="col-xl-12 col-md-12 mt-xl-5 col-12">
                  <h3 className="text-align-center">
                    ประเภทของเหล็กแบ่งได้อย่างไรบ้าง?
                  </h3>
                  <br/>
                  <h5>
                    สำหรับประเภทของเหล็กนั้น สามารถแบ่งออกได้เป็น 2 กลุ่มใหญ่ๆ
                    คือ
                  </h5>

                  <ol>
                    <h5>
                      <b>1. เหล็กหล่อ </b>{" "}
                      เป็นเหล็กที่ใช้วิธีการขึ้นรูปด้วยการหล่อขึ้นมา
                      ซึ่งจะมีปริมาณของธาตุคาร์บอนประมาณ 1.7-2%
                      จึงทำให้เหล็กมีความแข็ง แต่ในขณะเดียวกันก็มีความเปราะ
                      และด้วยเหตุนี้จึงทำให้เหล็กหล่อ
                      สามารถขึ้นรูปได้แค่วิธีการหล่อวิธีเดียวเท่านั้น
                      ไม่สามารถขึ้นรูปด้วยการรีดหรือวิธีการอื่นๆ ได้
                      นอกจากนี้เหล็กหล่อ ก็สามารถแบ่งย่อยๆ ได้ดังนี้
                    </h5>
                    <li>
                      <p>
                        <b>• เหล็กหล่อเทา </b>
                        เป็นเหล็กหล่อที่มีโครงสร้างคาร์บอนในรูปของกราฟไฟต์
                        เพราะมีคาร์บอนและซิลิคอนเป็นส่วนประกอบสูงมาก
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>• เหล็กหล่อขาว </b>เป็นเหล็กที่มีความแข็งแรงทนทานสูง
                        สามารถทนต่อการเสียดสีได้ดี แต่จะเปราะจึงแตกหักได้ง่าย
                        โดยเหล็กหล่อประเภทนี้
                        จะมีปริมาณของซิลิคอนต่ำกว่าเหล็กหล่อเทา
                        ทั้งมีคาร์บอนอยู่ในรูปของคาร์ไบด์ของเหล็กหรือที่เรียกกว่า
                        ซีเมนไตต์
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>• เหล็กหล่อกราฟไฟต์กลม </b>
                        เป็นเหล็กที่มีโครงสร้างเป็นกราฟไฟต์
                        ซึ่งจะมีส่วนผสมของแมกนีเซียมหรือซีเรียมอยู่ในน้ำเหล็ก
                        ทำให้เกิดรูปร่างกราฟไฟต์ทรงกลมขึ้นมา
                        ทั้งยังได้คุณสมบัติทางกลในทางที่ดีและโดดเด่นยิ่งขึ้น
                        เหล็กหล่อกราฟไฟต์จึงได้รับความนิยมในการนำมาใช้งานอย่างแพร่หลายและถูกนำมาใช้งานในอุตสาหกรรมมากขึ้น
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>• เหล็กหล่ออบเหนียว </b>
                        เป็นเหล็กที่ผ่านกระบวนการอบเพื่อให้ได้คาร์บอนในโครงสร้างคาร์ไบด์แตกตัวมารวมกับกราฟไฟต์เม็ดกลม
                        และกลายเป็นเฟอร์ไรด์หรือเพิร์ลไลต์
                        ซึ่งก็จะมีคุณสมบัติที่เหนียวแน่นกว่าเหล็กหล่อขาวเป็นอย่างมาก
                        ทั้งได้รับความนิยมในการนำมาใช้งานที่สุด
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>• เหล็กหล่อโลหะผสม </b>
                        เป็นประเภทของเหล็กที่มีการเติมธาตุผสมเข้าไปหลายอย่างด้วยกัน
                        ซึ่งก็จะช่วยปรับปรุงคุณสมบัติของเหล็กให้ดีขึ้น
                        โดยเฉพาะการทนต่อความร้อนและการต้านทานต่อแรงเสียดสีที่เกิดขึ้น
                        เหล็กหล่อประเภทนี้จึงนิยมใช้ในงานที่ต้องสัมผัสกับความร้อน
                      </p>
                    </li>
                    <br />
                  </ol>

                  <ol>
                    <h5>
                      <b>2. เหล็กกล้า </b>{" "}
                      เป็นเหล็กที่มีความเหนียวแน่นมากกว่าเหล็กหล่อ
                      ทั้งสามารถขึ้นรูปด้วยวิธีทางกลได้ จึงทำให้เหล็กชนิดนี้
                      นิยมถูกนำมาใช้อย่างแพร่หลายและกว้างขวางมากขึ้น
                      ตัวอย่างเหล็กกล้าที่มักจะพบได้บ่อยๆ ในชีวิตประจำวัน คือ
                      เหล็กแผ่น เหล็กโครงรถยนต์หรือเหล็กเส้น เป็นต้น
                      นอกจากนี้คาร์บอนก็สามารถแบ่งได้เป็นกลุ่มย่อยๆ ดังนี้
                    </h5>
                    <li>
                      <p>
                        <b>• เหล็กกล้าคาร์บอน </b>
                        จะมีส่วนผสมหลักเป็นคาร์บอนและมีส่วนผสมอื่นๆ
                        ปนอยู่บ้างเล็กน้อย
                        ทั้งนี้ก็ขึ้นอยู่กับจะมีธาตุอะไรติดมาในขั้นตอนการถลุงบ้าง
                        ดังนั้นเหล็กกล้าคาร์บอน จึงสามารถแบ่งเป็นย่อยๆ ได้อีก
                        ตามปริมาณธาตุที่ผสมดังนี้
                      </p>
                      <li>
                        <p>
                          <b>1. เหล็กคาร์บอนต่ำ </b>
                          มีคาร์บอนต่ำกว่า 0.2% และมีความแข็งแรงต่ำมาก
                          จึงนำมารีดเป็นแผ่นได้ง่าย เช่น เหล็กเส้น เหล็กแผ่น
                          เป็นต้น
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>2. เหล็กกล้าคาร์บอนปานกลาง </b>
                          มีคาร์บอนต่ำกว่า 0.2% และมีความแข็งแรงต่ำมาก
                          จึงนำมารีดเป็นแผ่นได้ง่าย เช่น เหล็กเส้น เหล็กแผ่น
                          เป็นต้น
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>3. เหล็กกล้าคาร์บอนสูง </b>
                          มีคาร์บอนต่ำกว่า 0.2% และมีความแข็งแรงต่ำมาก
                          จึงนำมารีดเป็นแผ่นได้ง่าย เช่น เหล็กเส้น เหล็กแผ่น
                          เป็นต้น
                        </p>
                      </li>
                    </li>

                    <li>
                      <p>
                        <b>• เหล็กกล้าผสม </b>
                        เป็นเหล็ก ที่มีการผสมธาตุอื่นๆ เข้าไปโดยเจาะจง
                        เพื่อให้คุณสมบัติของเหล็ก เป็นไปตามที่ต้องการ
                        โดยเหล็กประเภทนี้มักจะมีความสามารถในการต้านทานต่อการกัดกร่อนและสามารถนำไฟฟ้าได้
                        รวมถึงมีคุณสมบัติทางแม่เหล็กอีกด้วย
                        ซึ่งก็จะแบ่งออกได้เป็น 2 ประเภท คือ
                        เหล็กกล้าผสมต่ำและเหล็กกล้าผสมสูง นั่นเอง
                        โดยเหล็กกล้าผสมต่ำ
                        จะเป็นเหล็กกล้าที่มีการผสมด้วยธาตุอื่นๆ น้อยกว่า 10%
                        และเหล็กกล้าผสมสูง
                        จะเป็นเหล็กกล้าที่มีการผสมด้วยธาตุอื่นๆ มากกว่า 10%
                      </p>
                    </li>
                    <br />
                  </ol>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BrickBlock;
