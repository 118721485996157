import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
import ScrollIndicator from './components/ScrollIndicator';
import ScrollToTopRoute from './components/ScrollTopRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Home1 from './pages/Home1';
import Services from './pages/Services';
import BuildingMaterials from './pages/Product/BuildingMaterials';
import EngravedSign from './pages/Product/EngravedSign';
import Offering from './pages/Product/Offering';
import Pagoda from './pages/Product/Pagoda';
import Shrine from './pages/Product/Shrine';

import Brick from './pages/Product/BuildingMaterials/Brick';
import MarbleHorseTable from './pages/Product/BuildingMaterials/MarbleHorseTable';
import Iron from './pages/Product/BuildingMaterials/Iron';
import PrecastConcrete from './pages/Product/BuildingMaterials/PrecastConcrete';
import Pvc from './pages/Product/BuildingMaterials/Pvc';
import RockAndSandAndMortar from './pages/Product/BuildingMaterials/RockAndSandAndMortar';

function App() {
    SwiperCore.use([Autoplay])
    return (
        <>
            <Router>
                
                <ScrollToTopRoute />
                <Switch>
                    <Route exact path="/about">
                        <About />
                    </Route>
                    <Route exact path="/faq">
                        <Faq />
                    </Route>
                    <Route exact path="/services">
                        <Services />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/">
                        <Home1 />
                    </Route>
                    <Route exact path="/building-materials">
                        <BuildingMaterials />
                    </Route>
                    <Route exact path="/engraved-sign">
                        <EngravedSign />
                    </Route>
                    <Route exact path="/offering">
                        <Offering />
                    </Route>
                    <Route exact path="/pagoda">
                        <Pagoda />
                    </Route>
                    <Route exact path="/shrine">
                        <Shrine />
                    </Route>

                    <Route exact path="/Brick">
                        <Brick />
                    </Route>
                    <Route exact path="/marble-Horse-table">
                        <MarbleHorseTable />
                    </Route>
                    <Route exact path="/iron">
                        <Iron />
                    </Route>
                    <Route exact path="/precast-concrete">
                        <PrecastConcrete />
                    </Route>
                    <Route exact path="/pvc">
                        <Pvc />
                    </Route>
                    <Route exact path="/rock-and-sand">
                        <RockAndSandAndMortar />
                    </Route>
                </Switch>
            </Router>
            <ScrollIndicator />
        </>
    );
}

export default App;
