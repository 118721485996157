import React from 'react';
import LogoBanner from '../components/LogoBanner';
import Shipping from '../components/Shipping';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import Hero1 from '../components/Hero1';
import Portfolio1 from '../components/Portfolio1';
import Portfolio2 from '../components/Portfolio2';
import Promo from '../components/Promo';
import Services1 from '../components/Services1';

function Home1() {
    return (
        <>
            <Header1 />
            <Hero1 />
            <LogoBanner />
            <Promo />
            <Services1 />
            <Shipping />
            <Portfolio1 />
            <Portfolio2 />
            <Footer1 />
        </>
    );
}

export default Home1;
