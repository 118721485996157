import React from "react";
import Footer1 from "../../../components/Footer1";
import Header1 from "../../../components/Header1";
import Concrete from "../../../components/Concrete";
import bannerBg from "../../../assets/img/product/buildingmaterials/header-bg.webp";


function PrecastConcrete() {
  return (
    <>
      <Header1 />
      <section className="BuildingMaterials-section">
      <div className="text-align-center">
        <img src={bannerBg} alt="Building Materials" />
        </div>
      <Concrete />
      </section>
      <Footer1 />
    </>
  );
}

export default PrecastConcrete;
