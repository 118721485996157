import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import { Navigation, Autoplay, Pagination } from "swiper";

import img1 from "../../assets/img/product/Shrine/slide/img1.webp";
import img2 from "../../assets/img/product/Shrine/slide/img2.webp";
import img3 from "../../assets/img/product/Shrine/slide/img3.webp";
import img4 from "../../assets/img/product/Shrine/slide/img4.webp";
import img5 from "../../assets/img/product/Shrine/slide/img5.webp";

function ShrineSlide() {
  return (
    <section className="ShrineSlide-carousel-wrapper section-padding ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12 text-center">
            <div className="text_header">
              <h2>สินค้าขายดี</h2>
            </div>
          </div>
        
      </div>
      <Swiper
        spaceBetween={30}
        modules={[Navigation, Autoplay, Pagination]}
        pagination={{ clickable: true }}
        slidesPerView={3}
        loop={true}
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        className="ShrineSlide-carousel-wrapper owl-carousel"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },

          767: {
            slidesPerView: 2,
          },

          992: {
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide>
                    <img
                      src={img1}
                      alt="Project"
                      className="slide-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={img2}
                      alt="Project"
                      className="slide-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={img3}
                      alt="Project"
                      className="slide-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={img4}
                      alt="Project"
                      className="slide-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={img5}
                      alt="Project"
                      className="slide-image"
                    />
                  </SwiperSlide>
      </Swiper>
      
      </div>
    </section>
  );
}

export default ShrineSlide;
