import React, { useState } from "react";
import { FaBuilding, FaLandmark, FaRoad, FaTorah } from "react-icons/fa";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import aboutImg from "../../assets/img/about_us.webp";
import IconBox from "./IconBox";

import add_line from "../../assets/img/social/add_line.webp";
import fast_tel from "../../assets/img/social/fast_tel.webp";
import facebook_btn from "../../assets/img/social/saraburi_facebook_btn.webp";
import shopee_btn from "../../assets/img/social/shopee_btn.webp";
import lazada_btn from "../../assets/img/social/lazada_btn.webp";
import ig_btn from "../../assets/img/social/ig_btn.webp";

function AboutFeatured() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="about-wrapper section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-5 col-12 align-items-center">
              <div
                className="about-promo"
                style={{ backgroundImage: `url(${aboutImg})` }}
              ></div>
            </div>

            <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
              <div className="block-contents ml-lg-5">
                <h1>เราทำอะไร</h1>
                <h4>
                  ร้านสระบุรีอิฐบล๊อค วัสดุก่อสร้างยโสธร โรงงานผลิตอิฐบล็อก
                  ท่อปูน เสาปูน เสารั้ว จำหน่าย อิฐแดง อิฐช่องลม หิน ปูน ทราย
                  ท่อปูน ท่อใยหิน เหล็ก โต๊ะม้าหินอ่อน ฯลฯ ศาลพระภูมิ ศาลพระพรหม
                  ศาลเจ้าที่ เครื่องตั้งศาล ธาตุ เจดีย์ โกศเซรามิก โกศทองเหลือง
                  ป้ายหินแกะสลัก
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="icon-boxs col-md-6 col-xl-3 col-12">
              <IconBox
                icon={<FaBuilding />}
                heading="วัสดุก่อสร้าง"
                text="วัสดุที่ใช้ในจุดประสงค์สำหรับการก่อสร้าง รวมไปถึงสถาปัตยกรรมที่ถูกสร้างขึ้น"
              />
            </div>
            <div className="icon-boxs col-md-6 col-xl-3 col-12">
              <IconBox
                icon={<FaLandmark />}
                heading="ศาลพระภูมิ"
                text="ศาลที่สร้างขึ้นเพื่อให้เป็นที่สถิตของเทพารักษ์"
              />
            </div>
            <div className="icon-boxs col-md-6 col-xl-3 col-12">
              <IconBox
                icon={<FaLandmark />}
                heading="ธาตุ เจดีย์"
                text="สิ่งก่อสร้างหรือสิ่งของที่สร้างขึ้น เพื่อเป็นที่ระลึกถึงและเคารพบูชา"
              />
            </div>
            <div className="icon-boxs col-md-6 col-xl-3 col-12">
              <IconBox
                icon={<FaTorah />}
                heading="แกะสลักป้าย หินอ่อน"
                text="ยินดีให้คำปรึกษา และคำแนะนำในการสั่งทำป้ายอัฐิ"
              />
            </div>
          </div>

          <div className="col-xl-12 col-md-12 mt-5 col-12 align-center">
            <div className="row align-center">
              <div className="col-xl-3 col-md-4 col-sm-3 mt-3 col-12 align-center">
                <a href="tel:045712076">
                  <img src={fast_tel} alt="telephone" />
                </a>
              </div>
              <div className="col-xl-3 col-md-4 col-sm-3 mt-3 col-12 align-center">
                <a href="https://lin.ee/xhtCRxO">
                  <img src={add_line} alt="line" />
                </a>
              </div>

              <div className="col-xl-3 col-md-4 col-sm-3 mt-3 col-12 align-center">
                <a href="https://www.facebook.com/Sraburiitblock">
                  <img src={facebook_btn} alt="facebook" />
                </a>
              </div>
            </div></div>
            <div className="col-xl-12 col-md-12 mt-3 col-12 align-center">
            <div className="row align-center">
              <div className="col-xl-3 col-md-4 col-sm-3 mt-3 col-12 align-center">
                <a href="https://instagram.com/sangkhaphanyasothon?igshid=ZjE2NGZiNDQ=">
                  <img src={ig_btn} alt="instagram" />
                </a>
              </div>
              <div className="col-xl-3 col-md-4 col-sm-3 mt-3 col-12 align-center">
                <a href="https://s.lazada.co.th/s.8Jgfs">
                  <img src={lazada_btn} alt="lazada" />
                </a>
              </div>
              <div className="col-xl-3 col-md-4 col-sm-3 mt-3 col-12 align-center">
                <a href="https://shopee.co.th/athit_prai?smtt=0.62592294-1666163094.9&fbclid=PAAab4Rukelxkxhgb7Np23rwdeN6v3x7Kzu9YMlBmoQpc3bgFPg-DFaqyJGXQ">
                  <img src={shopee_btn} alt="shopee"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutFeatured;
