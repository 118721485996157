import React from 'react';

function Map() {
    return (
        <div id="map">
            <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.24487541997!2d104.17718559346582!3d15.791036898491251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3117c92d5d469721%3A0xe7a119a285b66c58!2z4Liq4Lij4Liw4Lia4Li44Lij4Li14Lit4Li04LiQ4Lia4Lil4LmK4Lit4LiE!5e0!3m2!1sth!2sth!4v1677684591201!5m2!1sth!2sth" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                frameBorder="0"
                style={{
                    border: '0',
                    width: '100%',
                }}
                allowFullScreen=""
                aria-hidden="false"
            />
        </div>
    );
}

export default Map;
