import React from "react";
import Footer1 from "../../../components/Footer1";
import Header1 from "../../../components/Header1";
import RockAndSandAndMortar from "../../../components/RockAndSandAndMortar";
import bannerBg from "../../../assets/img/product/buildingmaterials/header-bg.webp";

function RockAndSandAndMortar_Product() {
  return (
    <>
      <Header1 />
      <section className="BuildingMaterials-section">
      <div className="text-align-center">
          <img src={bannerBg} alt="Building Materials" />
        </div>
      <RockAndSandAndMortar />
      </section>
      <Footer1 />
    </>
  );
}

export default RockAndSandAndMortar_Product;
