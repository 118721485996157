import { FaNewspaper, FaBookmark, FaCheck,FaLuggageCart } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/approch1.webp';
import thumb2 from '../../assets/img/approch2.webp';
import thumb3 from '../../assets/img/approch3.webp';
import thumb4 from '../../assets/img/approch4.webp';

const approchData = [
    {
        id: uuidv4(),
        thumb: thumb1,
        icon: <FaNewspaper />,
        heading: 'อัพเดทข้อมูลให้ลูกค้าต่อเนื่อง',
        text: 'ส่งข้อมูลสินค้าใหม่ๆ หรือติดต่อปรึกษาทางไลน์  ไอจี หรือ เฟสบุ๊ค เป็นต้น ซึ่งจะมีการอัพเดตข้อมูลข่าวสารอย่างต่อเนื่อง เพื่อให้ลูกค้าทราบข้อมูลกิจกรรม หรือสินค้าจากทางร้าน',
    },

    {
        id: uuidv4(),
        thumb: thumb2,
        icon: <FaBookmark />,
        heading: 'ติดตามการให้บริการ',
        text: 'โดยหลังจากพนักงานขายสินค้าให้ลูกค้าไปแล้ว จะมีการสุ่มติดตามผล ความพึงพอใจของลูกค้า',
    },

    {
        id: uuidv4(),
        thumb: thumb3,
        icon: <FaCheck />,
        heading: 'เช็คสภาพสินค้า',
        text: 'เมื่อลูกค้าตกลงที่จะซื้อสินค้าชิ้นนั้น ทางร้านจะเช็คสภาพสินค้าและทำความสะอาดทุกครั้งก่อนชำระเงินด้วยว่า สินค้าชิ้นนั้นใช้งานได้ 100% เพื่อให้ลูกค้ามั่นใจในการบริการและสินค้า เป็นต้น',
    },

    {
        id: uuidv4(),
        thumb: thumb4,
        icon: <FaLuggageCart />,
        heading: 'มีบริการส่งของให้',
        text: 'บริการส่งสินค้าให้ถึงที่ ตามพื้นที่ให้บริการและเงื่อนไขของทางร้าน เพื่อความสะดวกสบายให้แก่ลูกค้า',
    },
];

export default approchData;
