import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import HeroBg1 from '../../assets/img/home1/hero1.webp';
import HeroBg2 from '../../assets/img/home1/hero2.webp';
import HeroBg3 from '../../assets/img/home1/hero3.webp';
import HeroBg4 from '../../assets/img/home1/hero4.webp';

function Hero1() {
    return (
        <section className="hero-slide-wrapper hero-1">
            <Swiper className="hero-slider-active owl-carousel">
                <SwiperSlide>
                    <div
                        className="single-slide hero-bg"
                        style={{ backgroundImage: `url(${HeroBg1})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-8">
                                    <div className="hero-contents">
                                        <h1>โรงงานผลิตอิฐบล็อก ท่อคอนกรีต เสาปูน เสารั้ว</h1>
                                        <Link to="/building-materials" className="theme-btn">
                                            ดูรายละเอียดเพิ่มเติม{' '}
                                            <BsArrowRight style={{ marginLeft: '10px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg2})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <div className="hero-contents">
                                        <h1>จำหน่าย อิฐมอญ หิน ปูน ทราย เหล็ก</h1>
                                        <a href="/Brick" className="theme-btn">
                                        ดูรายละเอียดเพิ่มเติม <BsArrowRight style={{ marginLeft: '10px' }} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg3})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <div className="hero-contents">
                                        <h1>จำหน่าย ศาลพระภูมิ ศาลพระพรหม ศาลเจ้าที่</h1>
                                        <a href="/shrine" className="theme-btn">
                                        ดูรายละเอียดเพิ่มเติม <BsArrowRight style={{ marginLeft: '10px' }} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg4})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <div className="hero-contents">
                                        <h1>จำหน่าย ธาตุ<br/>เจดีย์</h1>
                                        <a href="/pagoda" className="theme-btn">
                                        ดูรายละเอียดเพิ่มเติม <BsArrowRight style={{ marginLeft: '10px' }} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default Hero1;
