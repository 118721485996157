import React from "react";
import Footer1 from "../../components/Footer1";
import Header1 from "../../components/Header1";
import bannerBg from "../../assets/img/product/Shrine/header-bg.webp";
import phapoom_img1 from "../../assets/img/product/Shrine/phapoom/phapoom_img1.webp";
import phapoom_img2 from "../../assets/img/product/Shrine/phapoom/phapoom_img2.webp";
import phapoom_img3 from "../../assets/img/product/Shrine/phapoom/phapoom_img3.webp";
import phapoom_img4 from "../../assets/img/product/Shrine/phapoom/phapoom_img4.webp";
import phapoom_img5 from "../../assets/img/product/Shrine/phapoom/phapoom_img5.webp";
import phapoom_img6 from "../../assets/img/product/Shrine/phapoom/phapoom_img6.webp";
import phapoom_img7 from "../../assets/img/product/Shrine/phapoom/phapoom_img7.webp";
import phapoom_img8 from "../../assets/img/product/Shrine/phapoom/phapoom_img8.webp";
import phapoom_v1 from "../../assets/img/product/Shrine/phapoom/phapoom_v1.webp";
import phapoom_v2 from "../../assets/img/product/Shrine/phapoom/phapoom_v2.webp";

import phaphrom_img1 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img1.webp";
import phaphrom_img2 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img2.webp";
import phaphrom_img3 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img3.webp";
import phaphrom_img4 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img4.webp";
import phaphrom_img5 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img5.webp";
import phaphrom_img6 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img6.webp";
import phaphrom_img7 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img7.webp";
import phaphrom_img8 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img8.webp";
import phaphrom_img9 from "../../assets/img/product/Shrine/phaphrom/phaphrom_img9.webp";

import jaotee_img1 from "../../assets/img/product/Shrine/jaotee/jaotee_img1.webp";
import jaotee_img2 from "../../assets/img/product/Shrine/jaotee/jaotee_img2.webp";
import jaotee_img3 from "../../assets/img/product/Shrine/jaotee/jaotee_img3.webp";
import jaotee_img4 from "../../assets/img/product/Shrine/jaotee/jaotee_img4.webp";

import ShrineSlide from "../../components/ShrineSlide";

function Shrine() {
  return (
    <>
      <Header1 />
      <section className="Shrine-section">
        <div className="text-align-center">
          <img src={bannerBg} alt="Shrine" />
        </div>

        <div className="container">
          <div className="section-padding">
            <div className="Product1-header align-center text-align-center">
              <div className="row">
                <h2>
                  ร้านสระบุรีอิฐบล๊อค จำหน่าย ศาลพระภูมิ ศาลเจ้าที่ ศาลพระพรหม
                  เครื่องตั้งศาล
                </h2>
                <p>
                  ศาลพระภูมิ หมายถึง
                  ศาลที่สร้างขึ้นเพื่อให้เป็นที่สถิตของเทพารักษ์
                  พบได้ทั่วไปในประเทศไทย
                  และยังพบว่ามีปรากฏในประเทศลาวและประเทศกัมพูชาอีกด้วย
                  มีลักษณะเป็นบ้านหรือวิหารหลังเล็กตั้งอยู่บนเสาเดี่ยว
                  หรือปะรำทำจากปูนหรือไม้เป็นต้น ตั้งไว้ในจุดที่เชื่อว่าเป็นมงคล
                </p>
              </div>
            </div>
            <ShrineSlide />

            <div className="content_one">
              <div className="col-xl-12 mt-0 mt-xl-0 col-12 ">
                <div className="row">
                  <div className="col-xl-7 mt-0 mt-xl-0 col-12">
                    <h3>ควรตั้งศาลพระภูมิ พระพรหม ที่ไหน</h3>
                    <p>
                      1. ที่ตั้งศาลต้องเป็นบริเวณพื้นดิน
                      มิใช่บริเวณเดียวกับพื้นของตัวบ้าน
                      <br />
                      2. หากไม่มีพื้นที่ที่เป็นพื้นดิน
                      สามารถทำการตั้งศาลบนชั้นดาดฟ้าได้
                      แต่ส่วนใหญ่ศาลที่ตั้งบนดาดฟ้าจะเป็นศาลเทพต่างๆ เช่นพระพรหม
                      หรือ พระนารายณ์ มิใช่พระภูมิเจ้าที่
                      <br />
                      3. จุดที่ตั้งของศาลต้องไม่ถูกเงาของตัวบ้านทอดลงมาทับ
                      <br />
                      4. ที่ตั้งของศาลควรอยู่ห่างจากบริเวณที่ตั้งของห้องน้ำ
                      <br />
                      5. อย่าตั้งศาลให้อยู่ใกล้กับตัวบ้านมากนัก
                      <br />
                      6. อย่าหันหน้าศาลเข้าสู่บริเวณที่ตั้งของห้องน้ำ
                      <br />
                      7. ไม่ควรตั้งศาลให้หันหน้าตรงกับประตูหน้าบ้าน
                      <br />
                      8. ตั้งศาลให้ห่างจากรั้วหรือกำแพงบ้านอย่างน้อย 1 เมตร
                      <br />
                      9. ถ้าสามารถยกพื้นที่ตั้งศาลให้สูงขึ้นสัก 1 คืบ
                      จากพื้นดินได้ ก็เหมาะสมอย่างยิ่ง
                      <br />
                      10. ความสูงของศาล
                      ควรสูงเหนือระดับสายตาของผู้เป็นเจ้าของบ้านขึ้นไปเล็กน้อย
                      <br />
                    </p>
                  </div>
                  <div className="col-xl-4 mt-5 mt-xl-0 col-12 align-center">
                    <img className="" alt="Shrine" src={phapoom_v1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-bg">
          <div className="section-padding">
            <div className="container">
              <div className="text_header">
                <h2>ศาลพระภูมิ</h2>
              </div>
              <div className="content">
                <div className="col-xl-12 mt-xl-3 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phapoom_img1} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phapoom_img2} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phapoom_img3} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phapoom_img4} />
                    </div>
                  </div>

                  <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Shrine" src={phapoom_img5} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Shrine" src={phapoom_img6} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Shrine" src={phapoom_img7} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Shrine" src={phapoom_img8} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>ศาลเจ้าที่</h2>
            </div>
            <div className="content">
              <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                <div className="row">
                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Shrine" src={jaotee_img1} />
                  </div>

                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Shrine" src={jaotee_img2} />
                  </div>

                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Shrine" src={jaotee_img3} />
                  </div>

                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Shrine" src={jaotee_img4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-bg">
          <div className="section-padding">
            <div className="container">
              <div className="text_header">
                <h2>ศาลพระพรหม</h2>
              </div>
              <div className="content">
                <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img1} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img2} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img3} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img4} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img5} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img6} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img7} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Shrine" src={phaphrom_img8} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="content_one">
                <div className="col-xl-12 mt-5 mt-xl-5 col-12 ">
                  <div className="row">
                    <div className="col-xl-12 mt-5 mt-xl-0 col-12">
                      <h3>การตั้งศาลพระพรหม</h3>
                      <p>
                        การตั้งศาลพระพรหมผู้ทำพิธีตั้งอาจจะเป็นหมอชาวบ้านหรือพราหมณ์ก็ได้แต่ต้องเป็นผู้ที่ปฏิบัติตัวอยู่ในศีลในธรรม
                        มีความซื่อสัตย์สุจริต ยุติธรรม
                        จะทำให้การตั้งศาลพระพรหมบังเกิดผลดี
                        มีความเจริญรุ่งเรืองแก่เจ้าของบ้าน เจ้าของกิจการ
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                    <div className="row">
                      <div className="col-xl-9 mt-5 mt-xl-3 col-12">
                        <h3>ควรตั้งศาลพระภูมิ พระพรหม ที่ไหน</h3>
                        <p>
                          1. ที่ตั้งศาลต้องเป็นบริเวณพื้นดิน
                          มิใช่บริเวณเดียวกับพื้นของตัวบ้าน
                          <br />
                          2. หากไม่มีพื้นที่ที่เป็นพื้นดิน
                          สามารถทำการตั้งศาลบนชั้นดาดฟ้าได้
                          แต่ส่วนใหญ่ศาลที่ตั้งบนดาดฟ้าจะเป็นศาลเทพต่างๆ
                          เช่นพระพรหม หรือ พระนารายณ์ มิใช่พระภูมิเจ้าที่
                          <br />
                          3. จุดที่ตั้งของศาลต้องไม่ถูกเงาของตัวบ้านทอดลงมาทับ
                          <br />
                          4. ที่ตั้งของศาลควรอยู่ห่างจากบริเวณที่ตั้งของห้องน้ำ
                          <br />
                          5. อย่าตั้งศาลให้อยู่ใกล้กับตัวบ้านมากนัก
                          <br />
                          6. อย่าหันหน้าศาลเข้าสู่บริเวณที่ตั้งของห้องน้ำ
                          <br />
                          7. ไม่ควรตั้งศาลให้หันหน้าตรงกับประตูหน้าบ้าน
                          <br />
                          8. ตั้งศาลให้ห่างจากรั้วหรือกำแพงบ้านอย่างน้อย 1 เมตร
                          <br />
                          9. ถ้าสามารถยกพื้นที่ตั้งศาลให้สูงขึ้นสัก 1 คืบ
                          จากพื้นดินได้ ก็เหมาะสมอย่างยิ่ง
                          <br />
                          10. ความสูงของศาล
                          ควรสูงเหนือระดับสายตาของผู้เป็นเจ้าของบ้านขึ้นไปเล็กน้อย
                          <br />
                        </p>
                      </div>
                      <div className="col-xl-3 mt-5 mt-xl-3 col-12 align-center">
                        <img
                          className="img_content"
                          alt="Shrine"
                          src={phaphrom_img6}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 mt-5 mt-xl-5 col-12 ">
                      <div className="col-xl-12 mt-xl-0 col-12 align-center">
                      <img className="img_content" alt="Shrine" src={phapoom_v2} />
                      </div>
                      <div className="col-xl-12 mt-5 col-12">
                        <h3>เครื่องสังเวยศาลพระพรหม</h3>
                        <p>
                          1. บายศรี 1 คู่
                          <br />
                          2. หัวหมู 1 คู่
                          <br />
                          3. กุ้งพล่า ปลายำ
                          <br />
                          4. เผือก มันต้ม
                          <br />
                          5. ปลาช่อนนอนตอง
                          <br />
                          6. ถั่ว งา นมข้น
                          <br />
                          7. ผลไม้มงคล 9 ชนิด
                          <br />
                          8. ขนมคาว หวาน 9 ชนิด
                          <br />
                          9. ต้มแดง ต้มขาว
                          <br />
                          10. มะพร้าวน้ำหอม
                          <br />
                          11. หมากพลู
                          <br />
                          12. พวงมาลัยดาวเรือง ดอกไม้สด
                        </p>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 mt-5 mt-xl-5 col-12">
                      <h3>ขั้นตอนการตั้งศาลพระพรหม โดยพราหมณ์</h3>
                      <p>
                        1. เจ้าของสถานที่เชิญท่านพราหมณ์ไปดูสถานที่ตั้ง
                        เลือกทำเลและทิศที่เหมาะสำหรับตั้งศาลพระพรหม
                        <br />
                        2. ท่านพราหมณ์ดูฤกษ์ยามที่เหมาะสม
                        <br />
                        3. เจ้าของสถานที่เลือกซื้อศาล พร้อมอุปกรณ์ตั้งศาล
                        และเครื่องสังเวย
                        <br />
                        4. เมื่อถึงฤกษ์ตามวันที่กำหนด
                        เจ้าของบ้านต้องจัดเตรียมเครื่องสังเวยไว้เพื่อเตรียมทำพิธี
                        ท่านพราหมณ์ทำพิธีวางศิลาฤกษ์
                        จะวางในฐานชิ้นล่างสุดของศาลพระพรหม
                        สิ่งที่นำมาวางรากฐานประกอบไปด้วย แผ่นเงิน-ทอง-นาค,
                        อิฐเงิน-ทอง-นาค, พลอยนพเก้า, ข้าวตอก ถั่ว งา, ดอกไม้ 9
                        สี และไม้มงคล 9 ชนิด คือ
                        <br />
                        - ไม้ราชพฤกษ์ หมายถึงความเป็นใหญ่และมีอำนาจวาสนา
                        <br />
                        - ไม้ขนุน หมายถึง
                        หนุนให้ดีขึ้นร่ำรวยทำอะไรมีแต่คนเกื้อหนุน
                        <br />
                        - ไม้ชัยพฤกษ์ หมายถึง การมีชัยชนะศัตรู ชนะอุปสรรคต่างๆ
                        <br />
                        - ไม้ทองหลาง หมายถึง การมีทรัพย์สิน มีเงิน
                        มีทองใช้ไม่ขัดสน
                        <br />
                        - ไม้ไผ่สีสุก หมายถึง มีความสุขทางกายสบายใจ
                        ไร้ทุกข์โศกโรคภัย
                        <br />
                        - ไม้ทรงบาดาล หมายถึง ความมั่นคง
                        หรือทำให้บ้านมั่นคงแข็งแรง
                        <br />
                        - ไม้สัก หมายถึง ความมีศักดิ์ศรี ความมีเกียรติ
                        อำนาจบารมี คนเครารพนับถือและยำเกรง
                        <br />
                        - ไม้พะยูง หมายถึง พยุงฐานะให้ดีขึ้น
                        <br />
                        - ไม้กันเกรา หมายถึง ป้องกันภัยอันตรายต่างๆ หรืออีกชื่อ
                        หนึ่งว่า ตำเสา ซึ่งหมายถึงทำให้เสาเรือนมั่นคง
                        <br />
                        5. ท่านพราหมณ์สวดมนต์ทำพิธี
                        และอันเชิญองค์พระพรหมขึ้นสู่ศาล
                        <br />
                        6. เมื่อทำพิธีเสร็จแล้ว เชิญเจ้าของสถานที่ไหว้
                        และแจกจ่ายอาหารให้กับบริวารเพื่อเป็นอาหารทิพ
                        ถือว่าผู้ใดได้รับประทานอาหารจากพิธีแล้วจะไม่ป่วยไข้และทำการค้าราบรื่นร่ำรวย
                      </p>
                    </div>
                    <div className="col-xl-6 mt-5 mt-xl-5 col-12">
                    <img
                          className="img_content"
                          alt="Shrine"
                          src={phaphrom_img9}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer1 />
    </>
  );
}

export default Shrine;
