import React from "react";
import Bg1 from "../../assets/img/home1/bg1.webp";
import Bg3 from "../../assets/img/home1/bg3.webp";
import Bg4 from "../../assets/img/home1/bg4.webp";
import Bg2 from "../../assets/img/home1/bg2.webp";

function Services1() {
  return (
    <section className="services-wrapper services-1 section-bg section-padding">
      {/* <div className="section-bg-img"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="section-title text-center">
              <h1>บริการของเรา</h1>
              <br></br>
              <p>
                ร้านสระบุรีอิฐบล๊อค วัสดุก่อสร้างยโสธร โรงงานผลิตอิฐบล็อก ท่อปูน
                เสาปูน เสารั้ว จำหน่าย อิฐแดง อิฐช่องลม หิน ปูน ทราย ท่อปูน
                ท่อใยหิน เหล็ก โต๊ะม้าหินอ่อน ฯลฯ ศาลพระภูมิ ศาลพระพรหม
                ศาลเจ้าที่ เครื่องตั้งศาล ธาตุ เจดีย์ โกศเซรามิก โกศทองเหลือง
                ป้ายหินแกะสลัก{" "}
              </p>
            </div>
            
            <div className="row">
              <div className="table-service">
                <img className="img" src={Bg1} alt="Building Materials" />
                <div className="table-service-content">
                  <h3>จำหน่าย วัสดุก่อสร้าง</h3>
                  <p>
                    วัสดุก่อสร้าง คือ วัสดุที่ใช้ในจุดประสงค์สำหรับการก่อสร้าง
                    ที่เกี่ยวข้องกับที่อยู่อาศัยของมนุษย์และสิ่งมีชีวิตที่มนุษย์สร้างขึ้นรวมไปถึงสถาปัตยกรรมที่ถูกสร้างขึ้น
                    วัสดุก่อสร้างมีการหลากหลายในทางวัสดุตั้งแต่ ดินจนถึงโลหะ
                    พลาสติกหรือแก้ว
                    วัสดุแบ่งแยกในหลายด้านไม่ว่าโครงสร้างทางวัตถุ
                  </p>
                </div>
              </div>
              <div className="table-service">
                <img className="img" src={Bg2} alt="Building Materials" />
                <div className="table-service-content">
                  <h3>จำหน่าย ศาลพระภูมิ </h3>
                  <p>
                    ศาลพระภูมิ หมายถึง
                    ศาลที่สร้างขึ้นเพื่อให้เป็นที่สถิตของเทพารักษ์
                    พบได้ทั่วไปในประเทศไทย
                    และยังพบว่ามีปรากฏในประเทศลาวและประเทศกัมพูชาอีกด้วย
                    มีลักษณะเป็นบ้านหรือวิหารหลังเล็กตั้งอยู่บนเสาเดี่ยว
                    หรือปะรำทำจากปูนหรือไม้เป็นต้น
                    ตั้งไว้ในจุดที่เชื่อว่าเป็นมงคล
                  </p>
                </div>
              </div>
              <div className="table-service">
                <img className="img" src={Bg3} alt="Building Materials" />
                <div className="table-service-content">
                  <h3>จำหน่าย ธาตุ เจดีย์ </h3>
                  <p>
                    เจดีย์ หมายถึงสิ่งก่อสร้างหรือสิ่งของที่สร้างขึ้น
                    เพื่อเป็นที่ระลึกถึงและเคารพบูชา สถูป
                    หมายถึงสิ่งก่อสร้างเหนือหลุมฝังศพ
                    หรือสร้างขึ้นเพื่อบรรจุอัฐิธาตุของผู้ที่ล่วงลับไปแล้ว
                    เพื่อให้ลูกหลานและผู้เคารพนับถือได้สักการบูชา
                  </p>
                </div>
              </div>
              <div className="table-service">
                <img className="img" src={Bg4} alt="Building Materials" />
                <div className="table-service-content">
                  <h3>รับแกะสลักป้าย หินอ่อน</h3>
                  <p>
                    ยินดีให้คำปรึกษา และคำแนะนำในการสั่งทำป้ายอัฐิ
                    ที่ถูกต้องเหมาะสมกับแต่ละงาน ด้วยวัสดุเกรดทำป้าย
                    ไม่ใช่เกรดปูพื้น และรูปหินที่คงทนถาวรจริง สีไม่จืดจาง
                    แม้ต้องเจอแดดฝน
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services1;
