import React from "react";
import ApprochCard from "./ApprochCard";
import approchData from "./approchData";



function Approch() {
  return (
    <section className="our-approch-wrapper section-padding section-bg">
      <div className="container">
        <div className="row mb-30">
          <div className="col-12 col-lg-12">
            <div className="section-title text-center">
              <p>บริการทุกระดับประทับใจ</p>
              <h1>ซื้อสินค้ากับเราดียังไง ?</h1>
            </div>
          </div>
        </div>

        <div className="row">
          {approchData.map((data) => (
            <ApprochCard
              key={data.id}
              thumbnail={data.thumb}
              icon={data.icon}
              heading={data.heading}
              text={data.text}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Approch;
