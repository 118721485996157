import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import { Navigation, Autoplay, Pagination } from "swiper";
import PagodaSlideData from "./PagodaSlideData";
import PagodaSlideItem from "./PagodaSlideItem";

function ShrineSlide() {
  return (
    <section className="Pagoda-carousel-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12 text-center">
            <div className="text_header">
              <h2>สินค้าขายดี</h2>
            </div>
          </div>
        
      </div>
      <Swiper
        spaceBetween={30}
        modules={[Navigation, Autoplay, Pagination]}
        pagination={{ clickable: true }}
        slidesPerView={3}
        loop={true}
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        className="Pagoda-carousel-wrapper owl-carousel"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },

          767: {
            slidesPerView: 2,
          },

          992: {
            slidesPerView: 3,
          },
        }}
      >
        {PagodaSlideData.map((data) => (
          <SwiperSlide>
            <PagodaSlideItem key={data.id} img={data.image} />
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
    </section>
  );
}

export default ShrineSlide;
