import React, { useState } from "react";
import img1 from "../../assets/img/product/buildingmaterials/pvc/img1.webp";


function Pvc() {
  return (
    <>
      <section className="Pvc-section">
        <div className="section-padding">
          <div className="container">
            <div className="content_one">
              <div className="row">
                <div className="text_header">
                  <h2>ท่อ PVC - อุปกรณ์ PVC</h2>
                </div>

                <div className="col-xl-4 mt-5 col-md-6 mt-xl-3 col-12">
                  <div
                    className="content_img"
                    style={{ backgroundImage: `url(${img1})` }}
                  ></div>
                </div>
                <div className="col-xl-8 col-md-12 mt-5 mt-xl-3 col-12">
                  <div className="section-title">
                    <h3>
                      คุณสมบัติท่อและอุปกรณ์ ท่อ PVC สำหรับระบบประปาและระบายน้ำ
                    </h3>
                    <p>
                      • มีความเหนียวยืดหยุ่นตัวได้ดี <br/>
                      • ทนต่อแรงดันน้ำ <br/>
                      • ทนต่อการกัดกร่อน <br/>
                      • ไม่เป็นฉนวนนำไฟฟ้าเพราะไม่เป็นตัวนำไฟฟ้า เป็นวัสดุไม่ติดไฟ<br/>
                      • น้ำหนักเบาอีกทั้งยังราคาถูก{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pvc;
