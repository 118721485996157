import React from 'react';
import { Link } from 'react-router-dom';

function MainMenu({ hanldeOffset }) {
    return (
        <>
            <div className="main-menu-area">
                <div className="row align-center">
                    <div className="col-6 col-md-8 col-lg-10">
                        <div className="main-menu">
                            <ul>
                                <li>
                                    <Link to="/">หน้าแรก</Link>
                                </li>
                                <li>
                                    <Link to="/about">เกี่ยวกับเรา</Link>
                                </li>
                                <li>
                                    <Link to="#">สินค้าของเรา</Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/building-materials">วัสดุก่อสร้าง</Link>
                                            <ul>
                                                <li>
                                                <Link to="/Brick">อิฐ</Link>
                                                </li>
                                                <li>
                                                <Link to="/precast-concrete">คอนกรีตสำเร็จ</Link>
                                                </li>
                                                <li>
                                                <Link to="/pvc">ท่อ PVC - อุปกรณ์ PVC</Link>
                                                </li>
                                                <li>
                                                <Link to="/rock-and-sand">หิน / ทราย / ปูน</Link>
                                                </li>
                                                <li>
                                                <Link to="/iron">เหล็ก</Link>
                                                </li>
                                                <li>
                                                <Link to="/marble-Horse-table">โต๊ะม้าหินอ่อน</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/shrine">ศาลพระภูมิ ศาลเจ้าที่</Link>
                                        </li>
                                        <li>
                                            <Link to="/pagoda">ธาตุ เจดีย์</Link>
                                        </li>
                                        <li>
                                            <Link to="/engraved-sign">แกะสลักป้าย หินอ่อน</Link>
                                        </li>
                                        <li>
                                            <Link to="/offering">เครื่องสังฆภัณฑ์</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/services">บริการของเรา</Link>
                                </li>
                                <li>
                                    <Link to="/faq">คำถามที่พบบ่อย</Link>
                                </li>
                                <li>
                                    <Link to="/contact">ติดต่อเรา</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainMenu;
