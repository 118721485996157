import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import logo from "../../assets/img/logo.webp";
import WidgetAbout from "./WidgetAbout";
import WidgetGetinTouch from "./WidgetGetinTouch";
import WidgetGetinTouchData from "./WidgetGetinTouchData";

function Footer1() {
  // STATES
  const [email, setEmail] = useState("");

  // HANDLER
  const onchangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
  };
  return (
    <footer className="footer-1 footer-wrap">
      <div className="footer-widgets dark-bg">
        <div className="container">
          <div className="row">
            <div className="footer-bg-img">
              <img src={logo} alt="Saraburiitblock" width="450" height="100%" />
            </div>
            <div className="footer-description">
              <p>ร้านสระบุรีอิฐบล๊อค วัสดุก่อสร้างยโสธร โรงงานผลิตอิฐบล็อก ท่อปูน  เสาปูน เสารั้ว จำหน่าย อิฐแดง อิฐช่องลม หิน  ปูน ทราย ท่อปูน ท่อใยหิน เหล็ก โต๊ะม้าหินอ่อน ฯลฯ ศาลพระภูมิ ศาลพระพรหม ศาลเจ้าที่ เครื่องตั้งศาล ธาตุ เจดีย์ โกศเซรามิก โกศทองเหลือง ป้ายหินแกะสลัก</p>
            </div>
          </div>
          <div className="line-color">

          </div>

          <div className="row">
            {/* ABOUT WIDGET */}
            <WidgetAbout
              text="130 หมู่บ้านดอนมะยาง ถนนอรุณประเสริฐ ตำบลตาดทอง อำเภอเมือง จังหวัดยโสธร 35000"
              fbLink="https://www.facebook.com/Sraburiitblock"
              lineLink="https://lin.ee/xhtCRxO"
            />

            {/* NEWSLETTER WIDGET */}

            <div className="col-md-6 col-xl-3 col-12">
              <div className="single-footer-wid newsletter_widget">
                <div className="wid-title">
                  <h4>บริการของเรา</h4>
                </div>
                <div className="vertical-menu">
                  <FaAngleRight />
                  <a href="#">วัสดุก่อสร้าง</a>
                  <br></br>
                  <FaAngleRight />
                  <a href="#">โต๊ะม้าหินอ่อน</a>
                  <br></br>
                  <FaAngleRight />
                  <a href="#">
                    ศาลพระภูมิ / ศาลพระพรหม / ศาลเจ้าที่ / เครื่องตั้งศาล
                  </a>
                  <br></br>
                  <FaAngleRight />
                  <a href="#">ธาตุ / เจดีย์</a>
                  <br></br>
                  <FaAngleRight />
                  <a href="#">ป้ายแกะสลักหิน</a>
                  <br></br>
                  <FaAngleRight />
                  <a href="#">เครื่องสังฆภัณฑ์ </a>
                  <br></br>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3 col-12">
              <div className="single-footer-wid ml-15 site_info_widget">
                <div className="wid-title">
                  <h4>ช่องทางติดต่อ</h4>
                </div>
                <div className="contact-us" />
                {WidgetGetinTouchData.map((data) => (
                  <WidgetGetinTouch
                    key={data.id}
                    icon={data.icon}
                    heading={data.heading}
                    text={data.text}
                    text2={data.text2}
                    text3={data.text3}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer bottom */}
      <div className="footer-bottom">
        <div className="container">
          <div className="align-items-center">
            <div className="col-lg-4 col-12">
              <div className="copyright-info">
                <p>
                  &copy; Copyright By <a href="#">Saraburiitblock</a> - 2023
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer1;
