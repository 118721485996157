import { v4 as uuidv4 } from 'uuid';
import Img1 from '../../assets/img/project/port1.webp';
import Img2 from '../../assets/img/project/port2.webp';
import Img3 from '../../assets/img/project/port3.webp';
import Img4 from '../../assets/img/project/port4.webp';


const portfolioOneData = [
    {
        id: uuidv4(),
        image: Img1,
        category: 'Industrial',
        client: 'บริษัท เพชรวิศวกรรม',
        heading: 'ส่งศาลพระพรหม โมเดิร์น',
        desc: 'ขอบพระคุณภาพจากลูกค้ามากๆครับ',
    },

    {
        id: uuidv4(),
        image: Img2,
        category: 'Industrial',
        client: 'Miss Aree the Garden',
        heading: 'ส่งม้าหินอ่อน โมเดิร์น',
        desc: 'ขอบพระคุณภาพจากลูกค้ามากๆครับ',
    },

    {
        id: uuidv4(),
        image: Img3,
        category: 'Industrial',
        client: 'วัดทุ่งนางโอก',
        heading: 'ส่งโต๊ะม้าหิน',
        desc: 'บ้านทุ่งนางโอก ขอบพระคุณภาพจากลูกค้ามากๆครับ',
    },

    {
        id: uuidv4(),
        image: Img4,
        category: 'Industrial',
        client: 'ร้านอาหารเอกเขนก',
        heading: 'ส่งโต๊ะโมเดิร์น',
        desc: 'ขอบพระคุณภาพจากลูกค้ามากๆครับ',
    },
];

export default portfolioOneData;
