import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import sale1 from "../../assets/img/sale1.webp";
import sale2 from "../../assets/img/sale2.webp";
import sale3 from "../../assets/img/sale3.webp";
import sale4 from "../../assets/img/sale4.webp";
import sale5 from "../../assets/img/sale5.webp";
import sale6 from "../../assets/img/sale6.webp";
import sale7 from "../../assets/img/sale7.webp";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import { Navigation, Autoplay, Pagination } from "swiper";

function Promo() {
  return (
    <section className="promo-featured-wrapper section-padding">
      <div className="container">
        <div className="row align-center">
          <div className="col-xl-6 col-12 text-center">
            <img src={sale1} alt="age" />
          </div>
          <div className="col-xl-6 col-12">
            <div className="block-contents ml-xl-5 mt-5 mt-xl-0">
              <span>สินค้าขายดี</span>
              <h1>อิฐบล็อก</h1>
              <h4>ราคาถูก ระบายความร้อนได้ดี</h4>
              <p>
                อิฐบล็อก หรืออิฐคอนกรีต เป็นอิฐที่มีสีเทาและมีรูอยู่ตรงกลาง
                มีขนาดใหญ่เป็นมาตรฐานและมีราคาถูก
                เมื่อเทียบราคาในการก่อผนังบนพื้นที่เท่าๆกันกับอิฐมอญและอิฐมวลเบา
              </p>
              <Link to="/Brick" className="theme-btn">
                ดูสินค้า
                <BsArrowRight
                  style={{ fontSize: "20px", marginLeft: "15px" }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-12 mt-5">
          <Swiper
            spaceBetween={30}
            modules={[Navigation, Autoplay, Pagination]}
            slidesPerView={3}
            loop={true}
            autoplay={{
              delay: 2500,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            className="promo-slide"
            breakpoints={{
              0: {
                slidesPerView: 2,
              },

              767: {
                slidesPerView: 4,
              },

              992: {
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide className="slide-item">
              <a href="/marble-Horse-table">
                <img src={sale2} alt="marble horse table" className="img" />
              </a>
            </SwiperSlide>
            <SwiperSlide className="slide-item">
              <a href="/precast-concrete">
                <img src={sale3} alt="marble horse table" className="img" />
              </a>
            </SwiperSlide>
            <SwiperSlide className="slide-item">
              <a href="/shrine">
                <img src={sale4} alt="marble horse table" className="img" />
              </a>
            </SwiperSlide>
            <SwiperSlide className="slide-item">
              <a href="/pagoda">
                <img src={sale5} alt="marble horse table" className="img" />
              </a>
            </SwiperSlide>
            <SwiperSlide className="slide-item">
              <a href="/offering">
                <img src={sale6} alt="marble horse table" className="img" />
              </a>
            </SwiperSlide>
            <SwiperSlide className="slide-item">
              <a href="/iron">
                <img src={sale7} alt="marble horse table" className="img" />
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Promo;
