import React from 'react';

function LogoBanner() {
    return (
        <section className="LogoBanner-wrapper">
            <div className="container align-center">
                <div className="row align-center">
                    <h2>ร้านสระบุรีอิฐบล๊อค จำหน่าย วัสดุก่อสร้าง ศาลพระภูมิ ธาตุ เจดีย์ แกะสลักป้าย หินอ่อน</h2>
                </div>
            </div>
        </section>
    );
}

export default LogoBanner;
