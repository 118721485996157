import React from "react";
import bannerBg from "../../assets/img/product/EngravedSign/header-bg.webp";
import Footer1 from "../../components/Footer1";
import Header1 from "../../components/Header1";

import img1 from "../../assets/img/product/EngravedSign/img1.webp";
import img2 from "../../assets/img/product/EngravedSign/img2.webp";
import img3 from "../../assets/img/product/EngravedSign/img3.webp";
import img4 from "../../assets/img/product/EngravedSign/img4.webp";
import img5 from "../../assets/img/product/EngravedSign/img5.webp";
import img6 from "../../assets/img/product/EngravedSign/img6.webp";

function EngravedSign() {
  return (
    <>
      <Header1 />
      <section className="EngravedSign-section">
        <div className="text-align-center">
          <img src={bannerBg} alt="Saraburiitblock" />
        </div>

        <div className="container">
          <div className="section-padding">
            <div className="Product1-header align-center text-align-center">
              <div className="row">
                <h2>ร้านสระบุรีอิฐบล๊อค รับทำป้ายแกะสลักหิน ติดธาตุ เจดีย์</h2>
                <p>
                  ขนาดตามช่องติดธาตุ (กว้าง x ยาว x สูง)
                  โดยผู้เชี่ยวชาญด้านงานแกะสลักหินอ่อน ป้ายหินแกรนิต
                  มีช่างผู้ชำนาญการในการแกะสลักตัวอักษร, โลโก้, ชื่อร้านค้า,
                  วัด, ห้างร้าน, โรงแรม, โรงเรียน และสถานที่ราชการต่าง ๆ
                  เรามีความประณีตและละเอียดอ่อน
                  มีผลงานที่ได้รับความไว้วางใจจากลูกค้ามากมาย
                </p>
              </div>
            </div>
            <div className="col-xl-12 col-md-12 mt-3 col-12 align-center">
              <img src={img1} alt="carved stone sign" />
            </div>
            <div className="col-xl-12 col-md-12 mt-5 col-12 text-align-center">
              <h5>
                ร้านสระบุรีอิฐบล๊อค แกะสลักป้ายหิน ยินดีให้คำปรึกษา
                และคำแนะนำในการสั่งทำป้ายอัฐิ ที่ถูกต้องเหมาะสมกับแต่ละงาน
                ด้วยวัสดุเกรดทำป้าย ไม่ใช่เกรดปูพื้น และรูปหินที่คงทนถาวรจริง
                สีไม่จืดจาง แม้ต้องเจอแดดฝน อีกนานนับสิบๆปี
                จึงทำให้ร้านเราไม่ใช่ร้านที่ราคาถูกที่สุด แต่เป็นร้านที่
                “ลูกค้ามั่นใจว่า ไม่มีใครทำราคาถูกเท่าเรา ในเกรดงานเดียวกัน”
              </h5>
            </div>
            <div className="row">
              <div className="col-xl-4 col-md-6 mt-5 col-12 align-center">
                <img src={img2} alt="carved stone sign" />
              </div>
              <div className="col-xl-4 col-md-6 mt-5 col-12 align-center">
                <img src={img5} alt="carved stone sign" />
              </div>
              <div className="col-xl-4 col-md-12 mt-5 col-12 align-center">
                <img src={img6} alt="carved stone sign" />
              </div>
            </div>
            <div className="col-xl-12 col-md-12 mt-5 col-12 text-align-center">
              <h5>
                คิดจะทำบุญเปลี่ยนธาตุหลังใหม่หรือมีของเดิมอยู่แล้ว
                เจดีย์ให้ผู้ที่ล่วงลับไปแล้ว ป้ายหน้าธาตุซีดจาง
                <b> มองชื่อ-รูปไม่ชัด </b> อย่าลืมป้ายรูปที่ติดหน้าธาตุ
                ก็สำคัญนะครับ
                <b> ตอบโจทย์ป้ายสีชัด ไม่ซีดเร็ว ด้วยเทคนิคของที่ร้านครับ</b>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Footer1 />
    </>
  );
}

export default EngravedSign;
