import React from "react";
import bannerBg from "../../assets/img/product/Offering/header-bg.webp";
import Footer1 from "../../components/Footer1";
import Header1 from "../../components/Header1";

import logo from "../../assets/img/product/Offering/logo.webp";
import add_line from "../../assets/img/social/add_line.webp";
import fast_tel from "../../assets/img/social/fast_tel.webp";
import facebook_btn from "../../assets/img/social/facebook_btn.webp";
import shopee_btn from "../../assets/img/social/shopee_btn.webp";
import lazada_btn from "../../assets/img/social/lazada_btn.webp";
import ig_btn from "../../assets/img/social/ig_btn.webp";

import shopee_bg from "../../assets/img/product/Offering/shopee_bg.webp";

import WinterClothes_img1 from "../../assets/img/product/Offering/WinterClothes/img1.webp";
import WinterClothes_img2 from "../../assets/img/product/Offering/WinterClothes/img2.webp";
import WinterClothes_img3 from "../../assets/img/product/Offering/WinterClothes/img3.webp";
import WinterClothes_img4 from "../../assets/img/product/Offering/WinterClothes/img4.webp";

import Merit_img1 from "../../assets/img/product/Offering/Merit/img1.webp";
import Merit_img2 from "../../assets/img/product/Offering/Merit/img2.webp";
import Merit_img3 from "../../assets/img/product/Offering/Merit/img3.webp";
import Merit_img4 from "../../assets/img/product/Offering/Merit/img4.webp";
import Merit_img5 from "../../assets/img/product/Offering/Merit/img5.webp";
import Merit_img6 from "../../assets/img/product/Offering/Merit/img6.webp";

import Trijewor_img1 from "../../assets/img/product/Offering/Trijewor/img1.webp";
import Trijewor_img2 from "../../assets/img/product/Offering/Trijewor/img2.webp";
import Trijewor_img3 from "../../assets/img/product/Offering/Trijewor/img3.webp";
import Trijewor_img4 from "../../assets/img/product/Offering/Trijewor/img4.webp";
import Trijewor_img5 from "../../assets/img/product/Offering/Trijewor/img5.webp";
import Pha_Wan1 from "../../assets/img/product/Offering/Trijewor/Pha_Wan1.webp";

import LEDCandle_img1 from "../../assets/img/product/Offering/LEDCandle/img1.webp";
import LEDCandle_img2 from "../../assets/img/product/Offering/LEDCandle/img2.webp";
import LEDCandle_img3 from "../../assets/img/product/Offering/LEDCandle/img3.webp";
import LEDCandle_img4 from "../../assets/img/product/Offering/LEDCandle/img4.webp";

import Thread_img1 from "../../assets/img/product/Offering/Thread/img1.webp";
import Thread_img2 from "../../assets/img/product/Offering/Thread/img2.webp";

import Pillow_img1 from "../../assets/img/product/Offering/Pillow/img1.webp";
import Pillow_img2 from "../../assets/img/product/Offering/Pillow/img2.webp";
import Pillow_img3 from "../../assets/img/product/Offering/Pillow/img3.webp";
import Pillow_img4 from "../../assets/img/product/Offering/Pillow/img4.webp";
import Pillow_img5 from "../../assets/img/product/Offering/Pillow/img5.webp";
import Pillow_img6 from "../../assets/img/product/Offering/Pillow/img6.webp";

import Wreath_img1 from "../../assets/img/product/Offering/Wreath/img1.webp";
import Wreath_img2 from "../../assets/img/product/Offering/Wreath/img2.webp";
import Wreath_img3 from "../../assets/img/product/Offering/Wreath/img3.webp";
import Wreath_img4 from "../../assets/img/product/Offering/Wreath/img4.webp";

function Offering() {
  return (
    <>
      <Header1 />
      <section className="Offering-section">
        <div className="text-align-center">
          <img src={bannerBg} alt="Offering" />
        </div>
        <div className="container">
          <div className="section-padding">
            <div className="Product1-header align-center text-align-center">
              <div className="row">
                <h2>ร้านสระบุรีอิฐบล๊อค จำหน่าย สังฆภัณฑ์</h2>
                <p>
                  ศูนย์รวมสังฆภัณฑ์ จ.ยโสธร กองกฐิน สังฆทาน พระพุทธรูป
                  โต๊ะหมู่บูชา แกะสลักป้ายหินอ่อนติดเจดีย์ ธาตุฯลฯ งานบุญ งานบวช
                  งานศพ ครบจบที่ สระบุรีอิฐบล๊อค
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 col-md-6 mt-5 col-12 align-center">
                <img src={logo} alt="Offering" width="400" height="100%" />
              </div>
              <div className="col-xl-6 col-md-6 mt-5 col-12 align-center">
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-sm-6 mt-3 col-12 align-center">
                    <a href="tel:045712076">
                      <img src={fast_tel} alt="Offering" width="300" height="100%"/>
                    </a>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-6 mt-3 col-12 align-center">
                    <a href="https://lin.ee/2VPSWY1">
                      <img src={add_line} alt="Offering" width="300" height="100%" />
                    </a>
                  </div>

                  <div className="col-xl-6 col-md-6 col-sm-6 mt-3 col-12 align-center">
                    <a href="https://www.facebook.com/SangkhaphanYasothon">
                      <img src={facebook_btn} alt="Offering" width="300" height="100%" />
                    </a>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-6 mt-3 col-12 align-center">
                    <a href="https://instagram.com/sangkhaphanyasothon?igshid=ZjE2NGZiNDQ=">
                      <img src={ig_btn} alt="Offering" width="300" height="100%" />
                    </a>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-6 mt-3 col-12 align-center">
                    <a href="https://s.lazada.co.th/s.8Jgfs">
                      <img src={lazada_btn} alt="Offering" width="300" height="100%" />
                    </a>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-6 mt-3 col-12 align-center">
                    <a href="https://shopee.co.th/athit_prai?smtt=0.62592294-1666163094.9&fbclid=PAAab4Rukelxkxhgb7Np23rwdeN6v3x7Kzu9YMlBmoQpc3bgFPg-DFaqyJGXQ">
                      <img src={shopee_btn} alt="Offering" width="300" height="100%" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-bg">
          <div className="section-padding">
            <div className="container">
              <div className="text_header">
                <h2>สังฆทานเครื่องกันหนาว</h2>
                <p>(มี ผ้าห่ม อังสะ หมวก ถุงมือ ถุงเท้า )</p>
              </div>
              <div className="content">
                <div className="col-xl-12 mt-xl-5 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering"
                        src={WinterClothes_img1}
                      />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering"
                        src={WinterClothes_img2}
                      />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering"
                        src={WinterClothes_img3}
                      />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering"
                        src={WinterClothes_img4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>สังฆทานบุญ</h2>
            </div>
            <div className="Merit">
              <div className="col-xl-12 mt-xl-5 col-12">
                <div className="row">
                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Merit_img1} />
                  </div>

                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Merit_img2} />
                  </div>

                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Merit_img3} />
                  </div>

                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Merit_img4} />
                  </div>

                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Merit_img5} />
                  </div>

                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Merit_img6} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="shopee_img">
          <a href="https://shopee.co.th/athit_prai?smtt=0.62592294-1666163094.9&fbclid=PAAab4Rukelxkxhgb7Np23rwdeN6v3x7Kzu9YMlBmoQpc3bgFPg-DFaqyJGXQ">
            <img src={shopee_bg} alt="Offering" />
          </a>
        </section>

        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>ผ้าไตรจีวร 7 ชิ้น</h2>
              <p>
                มี ผ้าไตรเต็ม ประคดเอว จีวร สังฆาฏิ ผ้ากราบผ้ารับประเคน สบง
                อังสะ ผ้ารัดอก{" "}
              </p>
            </div>
            <div className="Trijewor">
              <div className="col-xl-12 mt-xl-3 col-12">
                <div className="row">
                  <div className="col-xl-6 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Trijewor_img1} />
                  </div>

                  <div className="col-xl-6 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Offering" src={Trijewor_img2} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-12 mt-3 mt-xl-3 col-12">
              <div className="container">
                <h3 className=" text-align-center">
                  ผ้าไตรจีวรโทเร และผ้าไตรมัสลิน แตกต่างกันอย่างไร
                </h3>
                <p>
                  <b>ผ้าไตรโทเร</b> เป็นผ้าบาง ดูแลง่าย ใส่สบาย ระบายอากาศได้ดี
                  แต่ไม่ค่อยทนทาน <br />
                  <b>ผ้าไตรมัสลิน</b> เป็นผ้าที่มีเนื้อละเอียด ให้สัมผัสนุ่ม
                  บางเบา ไม่ยับง่าย ระบายอากาศได้ดีกว่าผ้าโทเร
                  เหมาะสำหรับบรรยายกาศในประเทสไทย
                </p>
              </div>
            </div>
            <div className="Trijewor  align-center">
              <div className="col-xl-12 mt-xl-0 col-12 align-center">
                <div className="row">
                  <div className="col-xl-3 col-md-6 mt-3 mt-xl-5 col-12 align-center">
                    <img
                      className=""
                      alt="Offering Trijewor"
                      src={Trijewor_img3}
                    />
                  </div>

                  <div className="col-xl-3 col-md-6 mt-3 mt-xl-5 col-12 align-center">
                    <img
                      className=""
                      alt="Offering Trijewor"
                      src={Trijewor_img4}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 mt-3 mt-xl-5 col-12 align-center">
                    <img
                      className=""
                      alt="Offering Trijewor"
                      src={Trijewor_img5}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 mt-3 mt-xl-5 col-12 align-center">
                    <img
                      className=""
                      alt="Offering Trijewor"
                      src={Pha_Wan1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-bg">
          <div className="section-padding">
            <div className="container">
              <div className="text_header">
                <h2>ชุดธูปเทียน LED</h2>
                <p>ไฟเสมือนจริง หมดกังวลเรื่องควันไฟ/ไฟไหม้</p>
              </div>
              <div className="LEDCandle">
                <div className="col-xl-12 mt-xl-5 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mt-3 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering LED Candle"
                        src={LEDCandle_img1}
                      />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-3 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering LED Candle"
                        src={LEDCandle_img2}
                      />
                    </div>
                    <div className="col-xl-3 col-md-6 mt-3 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering LED Candle"
                        src={LEDCandle_img3}
                      />
                    </div>
                    <div className="col-xl-3 col-md-6 mt-3 mt-xl-0 col-12 align-center">
                      <img
                        className="img"
                        alt="Offering LED Candle"
                        src={LEDCandle_img4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>ด้ายมงคล 9 เส้น สายสิญจน์</h2>
              <p>
                ด้ายดิบ ใช้ในงานพิธีสำคัญ มัดข้อไม้ ข้อมือ โยงทำพิธี
                หรือทำพิธีทางศาสนาต่าง ๆ
              </p>
            </div>
            <div className="Thread">
              <div className="col-xl-12 mt-xl-5 col-12">
                <div className="row">
                  <div className="col-xl-6 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Thread"
                      src={Thread_img1}
                    />
                  </div>

                  <div className="col-xl-6 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Thread"
                      src={Thread_img2}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact container">
          <div className="col-xl-12 col-md-12 col-sm-3 mt-0 col-12 align-center">
            <div className="row align-center">
              <div className="col-xl-3 col-md-6 col-sm-1 mt-0 col-12 align-center">
                <a href="tel:045712076">
                  <img className="img" src={fast_tel} alt="Offering" />
                </a>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-1 mt-0 col-12 align-center">
                <a href="https://lin.ee/2VPSWY1">
                  <img className="img" src={add_line} alt="Offering" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>หมอนขิด</h2>
              <p>
                หมอนขิดเพื่อสุขภาพ มีความนุ่มและความยืดหยุ่นสูง
                รองรับสรีระเวลานอนทำให้ไม่ปวดเมื่อย ช่วยกระจายแรงกดทับ
                ระบายอากาศดี ปราศจากไรฝุ่นและแบคทีเรีย
              </p>
            </div>
            <div className="Pillow">
              <div className="col-xl-12 mt-xl-5 col-12">
                <div className="row">
                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Pillow"
                      src={Pillow_img1}
                    />
                  </div>

                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Pillow"
                      src={Pillow_img2}
                    />
                  </div>
                  <div className="col-xl-4 col-md-4 mt-5 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Pillow"
                      src={Pillow_img3}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-12 mt-xl-3 col-12">
                <div className="row">
                  <div className="col-xl-4 col-md-4 mt-3 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Pillow"
                      src={Pillow_img4}
                    />
                  </div>

                  <div className="col-xl-4 col-md-4 mt-3 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Pillow"
                      src={Pillow_img5}
                    />
                  </div>
                  <div className="col-xl-4 col-md-4 mt-3 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Pillow"
                      src={Pillow_img6}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>บริการ พวงหรีด พัดลมพวงหรีด</h2>
              <p>รับจัดพัดลมพวงหรีด ติดป้ายฟรี ส่งฟรีในเขตอำเภอเมืองยโสธร</p>
            </div>
            <div className="Wreath">
              <div className="col-xl-12 mt-xl-5 col-12">
                <div className="row">
                  <div className="col-xl-12 col-md-12 mt-5 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Wreath"
                      src={Wreath_img1}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-12 mt-xl-3 col-12">
                <div className="row">
                  <div className="col-xl-4 col-md-4 mt-3 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Wreath"
                      src={Wreath_img2}
                    />
                  </div>
                  <div className="col-xl-4 col-md-4 mt-3 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Wreath"
                      src={Wreath_img3}
                    />
                  </div>
                  <div className="col-xl-4 col-md-4 mt-3 mt-xl-0 col-12 align-center">
                    <img
                      className="img"
                      alt="Offering Wreath"
                      src={Wreath_img4}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer1 />
    </>
  );
}

export default Offering;
