import React, { useState } from "react";
import img1 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img1.webp";
import img2 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img2.webp";
import img3 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img3.webp";
import img4 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img4.webp";
import img5 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img5.webp";
import img6 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img6.webp";
import img7 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img7.webp";
import img8 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img8.webp";
import img9 from "../../assets/img/product/buildingmaterials/MarbleHorseTable/img9.webp";
import MarbleHorseTableSlide from "../../components/MarbleHorseTableSlide";

function MarbleHorseTable() {
  return (
    <>
      <section className="MarbleHorseTable-section">
        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>เซตโต๊ะเก้าอี้ ม้าหินอ่อน</h2>
            </div>
            <div className="content">
              <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                <div className="row">
                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img1} alt="Marble Horse Table" />
                    </div>
                    <h4>โต๊ะกลม เก้าอี้กลม 4 ที่นั่ง</h4>
                  </div>

                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img2} alt="Marble Horse Table" />
                    </div>
                    <h4>โต๊ะเหลี่ยม เก้าอี้เหลี่ยม 4 ที่นั่ง</h4>
                  </div>

                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img3} alt="Marble Horse Table" />
                    </div>
                    <h4>โต๊ะ 8 เหลี่ยม เก้าอี้ 8 เหลี่ยม 4 ที่นั่ง</h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 mt-5 mt-xl-5 col-12">
                <div className="row">
                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img4} alt="Marble Horse Table" />
                    </div>
                    <h4>โต๊ะ 4 เหลี่ยม เก้าอี้ 4 เหลี่ยม 4 ที่นั่ง</h4>
                  </div>

                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img5} alt="Marble Horse Table" />
                    </div>
                    <h4>
                      โต๊ะทรงมน เก้าอี้ทรงมน 4 ที่นั่ง
                      <br />
                      สีน้ำตาล
                    </h4>
                  </div>

                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img6} alt="Marble Horse Table" />
                    </div>
                    <h4>
                      โต๊ะทรงมน เก้าอี้ทรงมน 4 ที่นั่ง
                      <br />
                      สีเทา
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-xl-12 mt-5 mt-xl-5 col-12">
                <div className="row">
                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img7} alt="Marble Horse Table" />
                    </div>
                    <h4>
                      โต๊ะทรงมน เก้าอี้ทรงมน 4 ที่นั่ง
                      <br />
                      สีขาวเงา
                    </h4>
                  </div>

                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img8} alt="Marble Horse Table" />
                    </div>
                    <h4>
                      โต๊ะทรงมน เก้าอี้ทรงมน 4 ที่นั่ง
                      <br />
                      สีเทาเงา
                    </h4>
                  </div>

                  <div className="col-xl-4 mt-5 mt-xl-0 col-12">
                    <div className="col-xl-12 mt-5 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img9} alt="Marble Horse Table" />
                    </div>
                    <h4>
                      โต๊ะทรงมน เก้าอี้ทรงมน 4 ที่นั่ง
                      <br />
                      สีดำ
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 mt-5 mt-xl-0 col-12">
            <MarbleHorseTableSlide />
          </div>
        </div>
      </section>
    </>
  );
}

export default MarbleHorseTable;
