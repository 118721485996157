import React from "react";

import Footer1 from "../../../components/Footer1";
import Header1 from "../../../components/Header1";
import bannerBg from "../../../assets/img/product/buildingmaterials/header-bg.webp";
import MarbleHorseTable from "../../../components/MarbleHorseTable";

function MarbleHorseTable_Product() {
  return (
    <>
      <Header1 />
      <section className="BuildingMaterials-section">
      <div className="text-align-center">
          <img src={bannerBg} alt="Building Materials" />
        </div>
        <MarbleHorseTable />
      </section>
      <Footer1 />

    </>
  );
}

export default MarbleHorseTable_Product;
