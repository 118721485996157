import React from "react";
import bannerBg from "../../assets/img/product/buildingmaterials/header-bg.webp";
import imgH1 from "../../assets/img/product/buildingmaterials/h1.webp";
import imgH2 from "../../assets/img/product/buildingmaterials/h2.webp";
import imgH3 from "../../assets/img/product/buildingmaterials/h3.webp";
import Footer1 from "../../components/Footer1";
import BrickBlock from "../../components/BrickBlock";
import Concrete from "../../components/Concrete";
import Pvc from "../../components/Pvc";
import MarbleHorseTable from "../../components/MarbleHorseTable";
import RockAndSandAndMortar from "../../components/RockAndSandAndMortar";
import Header1 from "../../components/Header1";
import Iron from "../../components/Iron";

function BuildingMaterials() {
  return (
    <>
      <Header1 />
      <section className="BuildingMaterials-section">
        <div className="text-align-center">
          <img src={bannerBg} alt="Saraburiitblock" />
        </div>
        <div className="container">
          <div className="row">
            <div className="Product1-header section-padding align-center text-align-center">
              <div className="row">
                <h2>ร้านสระบุรีอิฐบล๊อค จำหน่าย วัสดุก่อสร้าง ครบจบที่เดียว</h2>
                <p>
                  โรงงานผลิตอิฐบล็อก ท่อปูน เสาปูน เสารั้ว จำหน่าย อิฐแดง
                  อิฐช่องลม หิน ปูน ทราย ท่อปูน ท่อใยหิน เหล็ก โต๊ะม้าหินอ่อน
                  ฯลฯ และมีบริการส่งถึงที่
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mt-5 mt-xl-1 col-12">
              <img src={imgH1} alt="fence post" />
            </div>
            <div className="col-xl-4 col-md-4 mt-5 mt-xl-1 col-12">
              <img src={imgH2} alt="marble horse" />
            </div>
            <div className="col-xl-4 col-md-4 mt-5 mt-xl-1 col-12">
              <img src={imgH3} alt="marble horse" />
            </div>
          </div>
        </div>
        <BrickBlock />
        <Concrete />
        <Pvc />
        <RockAndSandAndMortar />
        <MarbleHorseTable />
        <Iron />
      </section>
      <Footer1 />
    </>
  );
}

export default BuildingMaterials;
