import React, { useState } from "react";
import img1 from "../../assets/img/product/buildingmaterials/concrete/img1.webp";
import img2 from "../../assets/img/product/buildingmaterials/concrete/img2.webp";
import img3 from "../../assets/img/product/buildingmaterials/concrete/img3.webp";
import img4 from "../../assets/img/product/buildingmaterials/concrete/img4.webp";
import img5 from "../../assets/img/product/buildingmaterials/concrete/img5.webp";
import img6 from "../../assets/img/product/buildingmaterials/concrete/img6.webp";
import img7 from "../../assets/img/product/buildingmaterials/concrete/img7.webp";
import img8 from "../../assets/img/product/buildingmaterials/concrete/img8.webp";

function Concrete() {
  return (
    <>
      <section className="Concrete-section">
        <div className="section-padding">
          <div className="container">
            <div className="content_one">
              <div className="row">
                <div className="col-xl-4 mt-5 col-md-6 mt-xl-0 col-12">
                  <div
                    className="content_img"
                    style={{ backgroundImage: `url(${img1})` }}
                  ></div>
                </div>
                <div className="col-xl-4 mt-5 col-md-6 mt-xl-0 col-12">
                  <div
                    className="content_img"
                    style={{ backgroundImage: `url(${img2})` }}
                  ></div>
                </div>
                <div className="col-xl-4 col-md-12 mt-5 mt-xl-0 col-12">
                  <div className="section-title">
                    <h2>ท่อคอนกรีตเสริมเหล็ก (Concrete Pipe)</h2>
                    <p>
                      เหมาะสำหรับงานระบายน้ำทั่วไป การเลือกประเภทของท่อระบายน้ำ
                      ขึ้นอยู่กับความเหมาะสมในการใช้งาน{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-12 col-md-12 mt-5 mt-xl-5 col-12">
                  <h2 className="text-align-center">วงส้วม-วงบ่อ คอนกรีต</h2>
                  <div className="row">
                    <div className="col-xl-6 mt-5 col-md-12 mt-xl-3 col-12 text-align-center">
                      <img className="img" src={img3} alt="concrete" />
                    </div>
                    <div className="col-xl-6 mt-5 mt-xl-3 col-12 text-align-center">
                      <h3> ขนาดสินค้า : เส้นผ่าศูนย์กลาง (วัดนอก) x สูง</h3>
                      <h4>
                        <br />
                        1) วงบ่อ ขนาด 50 x สูง 30 ซม.
                        <br />
                        2) วงบ่อ ขนาด 50 x สูง 40 ซม.
                        <br />
                        3) วงบ่อ ขนาด 60 x สูง 40 ซม.
                        <br />
                        4) วงบ่อ ขนาด 80 x สูง 40 ซม.
                        <br />
                        5) วงบ่อ ขนาด 100 x สูง 50 ซม.
                        <br />
                        6) วงบ่อ ขนาด 120 x สูง 50 ซม.
                        <br />
                        <br />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-bg ">
          <div className="container">
            <div className="section-padding">
              <div className="row">
                <div className="col-xl-6 mt-5 mt-xl-0 col-12 text-align-center">
                  <h2> เสาปูน</h2>
                  <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                    <img className="img" src={img4} alt="concrete" />
                  </div>
                  <br />
                  <h3>
                    เสาปูนบ่าเดี่ยว , เสาปูนเหล็กโผล่ <br />
                    ขนาดสินค้า : หน้ากว้าง ขนาด 4", 5", 6", 7" (นิ้ว)
                  </h3>
                  <h4>
                    ความสูง 1 ม., 1.5 ม., 2 ม., 2.5 ม., 3ม., 3.5 ม., 4 ม.
                    <br />
                  </h4>
                </div>
                <div className="col-xl-6 mt-5 mt-xl-0 col-12 text-align-center">
                  <h2>เสารั้ว</h2>
                  <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                    <img className="img" src={img5} alt="concrete" />
                  </div>
                  <h4>
                    <br />
                    ขนาด 3" x 3" (นิ้ว)
                    <br />
                    ขนาด 4" x 4" (นิ้ว)
                    <br />
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section-padding">
            <div className="row">
              <div><h2 className="text-align-center">ท่อใยหิน</h2></div>
              
              <div className="col-xl-3 mt-5 mt-xl-3 col-md-6 col-12 text-align-center">
                <img className="img" src={img6} alt="concrete" />
              </div>
              <div className="col-xl-3 mt-5 mt-xl-3 col-md-6 col-12 text-align-center">
                <img className="img" src={img7} alt="concrete" />
              </div>
              <div className="col-xl-6 mt-5 mt-xl-3 col-12">
                <p>
                  ท่อใยหิน หรือท่อซีเมนต์
                  เป็นท่อที่เหมาะสำหรับการวางระบบระบายน้ำภายในอาคารที่มีน้ำหนักกดทับน้อย
                  เช่น ทางคนเดินผ่าน หรือในสวน เป็นต้น
                  <br />
                </p>
                <table>
                  <tr>
                    <td>ขนาด 4” x 100 ซม.</td>
                    <td>ขนาด 6” x 100 ซม.</td>
                    <td>ขนาด 8” x 100 ซม.</td>
                    <td>ขนาด 10” x 100 ซม.</td>
                  </tr>
                  <tr>
                    <td>ขนาด 4” x 150 ซม.</td>
                    <td>ขนาด 6” x 150 ซม.</td>
                    <td>ขนาด 8” x 150 ซม.</td>
                    <td>ขนาด 10” x 150 ซม.</td>
                  </tr>
                  <tr>
                    <td>ขนาด 4” x 200 ซม.</td>
                    <td>ขนาด 6” x 200 ซม.</td>
                    <td>ขนาด 8” x 200 ซม.</td>
                    <td>ขนาด 10” x 200 ซม.</td>
                  </tr>
                  <tr>
                    <td>ขนาด 4” x 250 ซม.</td>
                    <td>ขนาด 6” x 250 ซม.</td>
                    <td>ขนาด 8” x 250 ซม.</td>
                    <td>ขนาด 10” x 250 ซม.</td>
                  </tr>
                  <tr>
                    <td>ขนาด 4” x 300 ซม.</td>
                    <td>ขนาด 6” x 300 ซม.</td>
                    <td>ขนาด 8” x 300 ซม.</td>
                    <td>ขนาด 10” x 300 ซม.</td>
                  </tr>
                  <tr>
                    <td>ขนาด 4” x 350 ซม.</td>
                    <td>ขนาด 6” x 350 ซม.</td>
                    <td>ขนาด 8” x 350 ซม.</td>
                    <td>ขนาด 10” x 350 ซม.</td>
                  </tr>
                  <tr>
                    <td>ขนาด 4” x 400 ซม.</td>
                    <td>ขนาด 6” x 400 ซม.</td>
                    <td>ขนาด 8” x 400 ซม.</td>
                    <td>ขนาด 10” x 400 ซม.</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="section-padding section-bg">
          <div className="container">
            <div className="row">
              <div><h2 className="text-align-center">บ่อพักสำเร็จ</h2></div>
              
              <div className="col-xl-6 mt-5 mt-xl-3 col-12">
                <p>
                บ่อพักพร้อมฝาสำหรับท่อใยหิน เป็นบ่อพักซีเมนต์ที่มีฝาปิดบ่อ แต่ไม่มีพื้นก้นบ่อ โดยหน้างานจะต้องเทคอนกรีตที่พื้นก้นบ่อ เหมาะสำหรับการวางแนวท่อ ที่ไม่ต้องการ SLOPE และอยู่ในพื้นที่ที่น้ำหนักกดทับไม่มาก เช่น ทางคนเดินผ่าน
                  <br />
                </p>
                <h3 className="text-align-center">สินค้า มีขนาด 40 x 40 ซม.</h3>
              </div>
              <div className="col-xl-6 mt-5 mt-xl-3 col-12 text-align-center">
                <img className="img" src={img8} alt="concrete" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Concrete;
