import React from "react";
import Footer1 from "../../components/Footer1";
import Header1 from "../../components/Header1";
import bannerBg from "../../assets/img/product/Pagoda/header-bg.webp";

import tat_img1 from "../../assets/img/product/Pagoda/tat/tat_img1.webp";
import tat_img2 from "../../assets/img/product/Pagoda/tat/tat_img2.webp";
import tat_img3 from "../../assets/img/product/Pagoda/tat/tat_img3.webp";
import tat_img4 from "../../assets/img/product/Pagoda/tat/tat_img4.webp";
import tat_img5 from "../../assets/img/product/Pagoda/tat/tat_img5.webp";
import tat_img6 from "../../assets/img/product/Pagoda/tat/tat_img6.webp";
import tat_img7 from "../../assets/img/product/Pagoda/tat/tat_img7.webp";
import tat_img8 from "../../assets/img/product/Pagoda/tat/tat_img8.webp";
import tat_img9 from "../../assets/img/product/Pagoda/tat/tat_img9.webp";
import tat_img10 from "../../assets/img/product/Pagoda/tat/tat_img10.webp";
import tat_img11 from "../../assets/img/product/Pagoda/tat/tat_img11.webp";
import tat_img12 from "../../assets/img/product/Pagoda/tat/tat_img12.webp";

import jeadee_img1 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img1.webp";
import jeadee_img2 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img2.webp";
import jeadee_img3 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img3.webp";
import jeadee_img4 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img4.webp";
import jeadee_img5 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img5.webp";
import jeadee_img6 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img6.webp";
import jeadee_img7 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img7.webp";
import jeadee_img8 from "../../assets/img/product/Pagoda/Jeadee/jeadee_img8.webp";

import PagodaSlide from "../../components/PagodaSlide";

function Pagoda() {
  return (
    <>
      <Header1 />
      <section className="Pagoda-section">
        <div className="text-align-center">
          <img src={bannerBg} alt="Pagoda" />
        </div>

        <div className="container">
          <div className="section-padding">
            <div className="Product1-header align-center text-align-center">
              <div className="row">
                <h2>ร้านสระบุรีอิฐบล๊อค จำหน่าย ธาตุ เจดีย์</h2>
                <p>
                  เจดีย์ หมายถึงสิ่งก่อสร้างหรือสิ่งของที่สร้างขึ้น
                  เพื่อเป็นที่ระลึกถึงและเคารพบูชา สถูป
                  หมายถึงสิ่งก่อสร้างเหนือหลุมฝังศพ
                  หรือสร้างขึ้นเพื่อบรรจุอัฐิธาตุของผู้ที่ล่วงลับไปแล้ว
                  เพื่อให้ลูกหลานและผู้เคารพนับถือได้สักการบูชา
                </p>
              </div>
            </div>
            <PagodaSlide />
          </div>
        </div>

        <div className="section-bg">
          <div className="section-padding">
            <div className="container">
              <div className="text_header">
                <h2>ธาตุ</h2>
              </div>
              <div className="content">
                <div className="col-xl-12 mt-xl-3 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Pagoda" src={tat_img1} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Pagoda" src={tat_img2} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Pagoda" src={tat_img3} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Pagoda" src={tat_img4} />
                    </div>
                  </div>

                  <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img5} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img6} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img7} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img8} />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img9} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img10} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img11} />
                      </div>

                      <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                        <img className="img" alt="Pagoda" src={tat_img12} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding">
          <div className="container">
            <div className="text_header">
              <h2>เจดีย์</h2>
            </div>
            <div className="content">
              <div className="col-xl-12 mt-xl-3 col-12">
                <div className="row">
                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Pagoda" src={jeadee_img1} />
                  </div>

                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Pagoda" src={jeadee_img2} />
                  </div>

                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Pagoda" src={jeadee_img3} />
                  </div>

                  <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                    <img className="img" alt="Pagoda" src={jeadee_img4} />
                  </div>
                </div>

                <div className="col-xl-12 mt-5 mt-xl-3 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="ShPagodarine" src={jeadee_img5} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Pagoda" src={jeadee_img6} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Pagoda" src={jeadee_img7} />
                    </div>

                    <div className="col-xl-3 col-md-6 mt-5 mt-xl-0 col-12 align-center">
                      <img className="img" alt="Pagoda" src={jeadee_img8} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer1 />
    </>
  );
}

export default Pagoda;
