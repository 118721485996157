import React from "react";

import shipping from "../../assets/img/shipping.webp";

function Shipping() {
  return (
    <section className="shipping-section">
      <a href="https://www.facebook.com/Sraburiitblock"><img className="img" src={shipping} alt="building materials" /></a>
    </section>
  );
}

export default Shipping;
